.cdk-global-scrollblock {
  overflow-y: auto;
  top: 0!important;
}

.form-full-width {
  width: 100%;
}

.form-half-width {
  width: 50%;
}

.one-columns-container {
  display: flex;
  flex-direction: column;

  .column {
    width: 100%;
  }
}

.container-2-columns {
  flex-direction: row;
  display: flex;
  align-items: center;

  .column {
    width: calc(50% - 7px);
  }

  .column-space {
    margin-right: 15px;
  }
}

.container-3-columns {
  flex-direction: row;
  display: flex;
  align-items: center;

  .column {
    width: 33%;
  }

  .column-space {
    margin-right: 15px;
  }
}

.container-1-columns {
  display: flex;
  flex-direction: column;

  .column {
    width: 100%;
  }
}

html, body {
  height: 100%;
  width: 100%;
  margin: 0;
  font-family: $timeline-font;
  color: $timeline-color-black;
  box-sizing: border-box;
  overflow: hidden;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: cover !important;
}

@media print {
  body {
    height: unset;
  }
}

/*
TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.
*/
#app-nav-user-photo-btn > .mat-button-wrapper {
  padding: 0;
}

.roche-color {
  color: $timeline-color;
}

.roche-bg-color {
  background-color: $timeline-color;
  color: white;
}

.roche-bg-color-white {
  background-color: $timeline-color-white;
  color: $timeline-color;
}

.roche-bg-color-red {
  background-color: $timeline-color-red;
  color: white;
}

.rouche-bg-color-orange {
  background-color: $timeline-border-color-orange;
  color: white;
}

/*
TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version.
*/
.mat-card-header-text {
  width: 100%;
}

/*
TODO(mdc-migration): The following rule targets internal classes of input that may no longer apply for the MDC version.
*/
.one-row-form-field .mat-input-wrapper {
  margin-right: 10px;
}

.create-event-dialog-big {
  display: flex;
  justify-content: center;
}

.create-event-dialog-big-event {
  display: flex;
  justify-content: center;
  position: relative !important;
  left: calc(50% - 45vw) !important;;
}

.create-event-dialog-big .mat-mdc-dialog-container {
  max-width: 1000px;
}

.create-welcome-screen-dialog-big {
  display: flex;
  justify-content: center;
  max-width: none !important;
  height: 85vh;
}

/*
.create-welcome-screen-dialog-big .mat-dialog-container {
  max-width: none;
}
*/


.create-event-dialog .mat-mdc-dialog-container {
  max-width: 100%;
}

.module-selector-container .mat-mdc-dialog-container {
  border-radius: 20px;
  padding: 24px;
}

.br-20 .mat-mdc-dialog-container .mdc-dialog__surface {
  border-radius: 20px;
}

.mat-mdc-dialog-content {
  overflow-x: hidden !important;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}

.vertical-center {
  display: flex;
  align-items: center;
}

[hidden] {
  display: none !important;
}


/*
TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.
*/
.mat-checkbox-ripple {
  height: 0 !important;
  width: 0 !important;;
}

.font-comments {
  font-style: italic;
  font-variant-ligatures: normal;
  font-variant-caps: normal;
  font-variant-numeric: normal;
  font-variant-east-asian: normal;
  font-weight: 100;
  font-stretch: normal;
  font-size: 12px;
  line-height: 20px;
}

.mat-mdc-menu-panel.mat-mdc-menu-panel.menu-height-220 {
  max-height: 220px;
}

/*
.mat-mdc-menu-panel.mat-mdc-menu-panel.timeline-section-menu {
  max-width: 315px;
  &.one-item {
    min-height: 45px;
  }
}
*/

.mat-mdc-menu-panel.mat-mdc-menu-panel.chat-menu {
  max-width: 315px;
}

.mat-mdc-menu-panel.mat-mdc-menu-panel.pin-edit-menu {
  min-height: 45px;
  max-width: 350px;
}

.mat-mdc-menu-panel.mat-mdc-menu-panel.line-path-edit-menu {
  min-height: 45px;
  max-width: 350px;
}

.mat-mdc-menu-panel.mat-mdc-menu-panel.follow-me-settings-menu {
  max-width: fit-content;
  padding: 8px 40px 8px 1em;
  .mat-mdc-menu-content {
    display: flex;
    flex-direction: column;
  }
}

.mat-mdc-menu-panel.mat-mdc-menu-panel.paginator-main-actions {
  background: $timeline-color-main-action-menu;
  border-radius: 0;
}

.mat-mdc-menu-panel.mat-mdc-menu-panel.manage-time-date-editor-menu {
  max-width: 200px;
  overflow: hidden;
  width: 200px;

  &.date-mode {
    max-width: 400px;
    width: 260px;
  }

  &.time-mode {
    max-width: 200px;
    min-height: 62px;
    width: 200px;
  }

  .mat-mdc-menu-content {
    padding: 0;
  }

  &.round-param-mode {
    min-height: 44px;
  }

  &.management-note-mode {
    max-width: 260px;
    width: 255px;
  }

  &.day-break-mode {
    max-width: 205px;
  }
}

.mat-mdc-menu-panel.mat-mdc-menu-panel.long-menu-panel {
  max-width: 315px;
}

.mat-mdc-menu-panel.mat-mdc-menu-panel.instant-group-menu-panel {
  max-width: 400px;
}

.mat-mdc-menu-panel.mat-mdc-menu-panel.send-content-message {
  max-width: 320px;
  border-radius: 5px;
  min-height: auto;

  .mat-mdc-menu-content {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 7px;
  }
}

.mat-mdc-menu-panel.mat-mdc-menu-panel.send-content-message.personal-note {
  border-radius: 10px;

  .mat-mdc-menu-content {
    padding-left: 15px;
    margin-right: 5px;
  }
}

.mat-mdc-menu-panel.mat-mdc-menu-panel.convert-content-menu {
  .mat-mdc-menu-content {
    padding-top: 5px;
    padding-bottom: 5px;
  }
}

.mat-mdc-menu-panel.mat-mdc-menu-panel.no-top-bottom-padding {
  .mat-mdc-menu-content {
    padding-top: 2px;
    padding-bottom: 2px;
  }
}

.mat-mdc-menu-panel.mat-mdc-menu-panel.mat-menu-select-icons {
  border-radius: 16px;
  .mat-mdc-menu-content {
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.mat-mdc-menu-panel.mat-mdc-menu-panel.mat-menu-select-text-color {
  border-radius: 16px;
  background-color: $timeline-background-color-light;
  box-shadow: unset;
  .mat-mdc-menu-content {
    padding: 8px 4px;
  }
}

.mat-mdc-menu-panel.mat-mdc-menu-panel.mat-menu-select-text-align {
  border-radius: 16px;
  background-color: $timeline-background-color-light;
  box-shadow: unset;
  min-width: auto;
  min-height: auto !important;
  .mat-mdc-menu-content {
    padding: 0;
    .text-align-list {
      display: flex;
      border-radius: 1em;
      background-color: $timeline-background-color-light;
      align-items: center;
      padding: 4px 6px;
      gap: 2px;
      font-size: 0.9em;
      .text-align-button {
        background-color: transparent;
        padding: 0.5em;
        border: 0 solid $timeline-grid-row-border;
        border-radius: 0.9em;
        font-size: 0.82em;
        display: flex;
        align-items: center;
        justify-content: center;
        .button-icon {
          font-size: 1.8em;
          color: $timeline-color-black;
        }
        &:hover {
          cursor: pointer;
          background-color: $timeline-background-color-hover;
          transition: 0.2s ease;
        }
        &.active {
          background: $timeline-background-color-active;
          &:hover {
            background-color: $timeline-border-color;
          }
        }
        &.translate {
          .mat-icon {
            width: 1.8em;
            height: 1.8em;
          }
        }
        &.button-double-icon {
          .mat-icon {
            opacity: 0.2;
          }
          .material-icons-outlined {
            position: absolute;
            font-size: 1.8em;
          }
        }
      }
    }
  }
}

/*
TODO(mdc-migration): The following rule targets internal classes of menu that may no longer apply for the MDC version.
*/
.mat-mdc-menu-panel.mat-mdc-menu-panel.mat-menu-select-text-font-size,
.mat-mdc-menu-panel.mat-mdc-menu-panel.mat-menu-select-mark {
  border-radius: 16px;
  background-color: $timeline-background-color-light;
  box-shadow: unset;
  min-height: 32px !important;
  .mat-mdc-menu-content {
    padding: 0;
    .font-size-list,
    .mark-list {
      display: flex;
      flex-direction: column;
      border-radius: 16px;
      background-color: $timeline-background-color-light;
      align-items: center;
      padding: 2px 2px;
      gap: 2px;
      .font-size-button {
        background-color: transparent;
        padding: 0.4rem;
        min-width: 1.5rem;
        border: 0 solid $timeline-grid-row-border;
        border-radius: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        &:hover {
          cursor: pointer;
          background-color: $timeline-background-color-hover;
          transition: 0.2s ease;
        }
        &.active {
          background: $timeline-background-color-active;
          &:hover {
            background-color: $timeline-border-color;
          }
        }
      }
      .mark-button {
        background-color: transparent;
        padding: 0.4rem 0.6rem;
        min-width: 1.5rem;
        border: 0 solid $timeline-grid-row-border;
        border-radius: 12px;
        display: flex;
        align-items: center;
        width: 100%;
        text-align: left;
        &:hover {
          cursor: pointer;
          background-color: $timeline-background-color-hover;
          transition: 0.2s ease;
        }
        &.active {
          background: $timeline-background-color-active;
          &:hover {
            background-color: $timeline-border-color;
          }
        }
        &.sub-menu-button {
          position: relative;
          padding-right: 25px;
          &:after {
            content: "\1F782";
            color: $timeline-color-black;
            font-size: 12px;
            position: absolute;
            right: 8px;
            top: 5px;
          }
        }
        .mark-button-caption {
          display: flex;
          align-items: center;
          gap: 5px;
          mat-icon {
            color: $timeline-color-black-light-50;
          }
        }
      }
      .ai-text-operation-button {
        background-color: transparent;
        padding: 0.6rem;
        min-width: 1.5rem;
        border: 0 solid $timeline-grid-row-border;
        border-radius: 12px;
        display: flex;
        align-items: center;
        justify-content: left;
        width: 100%;
        &:hover {
          cursor: pointer;
          background-color: $timeline-background-color-hover;
          transition: 0.2s ease;
        }
      }
    }
  }
}

.mat-mdc-menu-panel.mat-mdc-menu-panel.mat-menu-select-background-color {
  border-radius: 16px;
  background-color: $timeline-background-color-light;
  min-width: 50px;
}

.mat-mdc-menu-panel.mat-mdc-menu-panel.mat-menu-select-number {
  border-radius: 16px;
  background-color: $timeline-background-color-light;
  min-width: 50px;
}

.mat-mdc-menu-panel.mat-mdc-menu-panel.content-container-toolbar-menu {
  border-radius: 10px;
  --mat-mdc-menu__panel-max-width: 330px;
  .mat-mdc-menu-content {
    background-color: #F1F3F5;
  }
  &.no-shadow {
    box-shadow: none;
  }
  &.border-radius-20 {
    border-radius: 20px;
  }
}

.mat-mdc-menu-panel.mat-mdc-menu-panel.desktop-create-event-wizard {
  display: none;
  pointer-events: none;
}

.mat-elevation-z4.select-color {
  border: 1px solid #d3d3d3ab;
  box-shadow: none;
  border-radius: 0 !important;
  max-width: 155px;
}

.mat-elevation-z4.select-color {
  max-width: 275px;
}

.mat-elevation-z2.send-content-message {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12), 2px -2px 1px -2px rgba(0, 0, 0, .2), -2px -2px 1px -2px rgba(0, 0, 0, .2);
}

.mat-mdc-menu-panel.mat-mdc-menu-panel.menu-sort-and-filter {
  max-width: 500px;
}

.mat-mdc-menu-panel.mat-mdc-menu-panel.dashboard-menu-filter {
  max-width: 300px;
}

.mat-elevation-z2.menu-sort-and-filter {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12), 2px -2px 1px -2px rgba(0, 0, 0, .2), -2px -2px 1px -2px rgba(0, 0, 0, .2);
}

.mat-mdc-menu-panel.mat-mdc-menu-panel.default-wrap-up-delta {
  max-width: 315px;
}

.mat-mdc-menu-panel.mat-mdc-menu-panel.toolbar-social-panel {
  max-width: max-content;
}

/*
TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.
*/
.hour.value-null .mat-select-value .mat-select-value-text {
  float: right;
}

/*
TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.
*/
.minute.value-null .mat-select-value .mat-select-value-text {
  float: left;
}

.select-user-filter {
  overflow-y: hidden !important;
}

.mat-mdc-table-row-first-last-of-type {
  .mat-mdc-cell:first-of-type {
    padding-left: 0;
  }

  mat-cell:last-of-type {
    padding-right: 0;
  }

  mat-header-cell:first-of-type {
    padding-left: 0;
  }

  mat-header-cell:last-of-type {
    padding-right: 0;
  }
}

.uppy-Dashboard--modal {
  z-index: 1000000000 !important;
  position: fixed;
}

.uppy-Dashboard--modal .uppy-Dashboard-inner {
  z-index: 20000 !important;
}

.cdk-overlay-container {
  z-index: 1000000000;
}

.scroll-icon-full-screen {
  color: $timeline-color;
  top: 1px;
  position: absolute;
  left: 1px;
  width: 43px;
  height: 43px;
  line-height: 43px;
  text-align: center;
  font-size: 35px;

  &.prev {
    transform: rotate(180deg);
    left: 0;
  }
}

.html-text-editor-textarea {
  b {
    font-weight: bold;
  }

  ul {
    list-style: none;
    padding-left: 22px;

    li {
      list-style: none;
      position: relative;

      &:before {
        content: ".";
        width: 7px;
        height: 7px;
        background: $timeline-color;
        color: transparent;
        position: absolute;
        top: 5px;
        left: -21px;
      }
    }
  }

  ul ul li:before {
    background: $timeline-color-black;
    width: 9px;
    height: 1px;
    top: 8px;
  }

  ul ul ul {
    padding-left: 16px;

    li:before {
      border-radius: 50%;
      width: 7px;
      height: 7px;
      top: 5px;
      left: -16px;
    }
  }

  ol {
    list-style: none;
    padding-left: 0;
    counter-reset: list1;

    li {
      position: relative;
      padding-left: 21px;

      &:before {
        position: absolute;
        counter-increment: list1;
        content: counter(list1) " ";
        left: 0;
      }
    }
  }

  ol ol {
    counter-reset: list2;

    li {
      position: relative;
      padding-left: 50px;

      &:before {
        position: absolute;
        counter-increment: list2;
        content: counter(list1) "." counter(list2);
        left: 21px;
      }
    }
  }

  ol ol ol {
    padding-left: 66px;
    counter-reset: list2;

    li {
      position: relative;
      padding-left: 0;

      &:before {
        position: absolute;
        content: ".";
        border-radius: 50%;
        background: $timeline-color-black;
        min-width: 7px;
        height: 7px;
        top: 5px;
        left: -16px;
        color: transparent;
      }
    }
  }

  ol ol ol ol {
    padding-left: 0;
    counter-reset: list3;

    li {
      position: relative;
      padding-left: 20px;

      &:before {
        all: initial;
        position: absolute;
        counter-increment: list3;
        content: counter(list3);
        font: inherit;
        left: 0;
      }
    }
  }

  ol ol ol ol ol {
    counter-reset: list3;
    padding-left: 20px;

    li {
      &:before {
        counter-increment: list3;
        content: counter(list3);
      }
    }
  }
}

.editor.detail-mode {
  .html-text-editor-textarea {
    b {
      font-weight: bold;
    }

    &.read-only {
      ul {
        list-style: none;
        padding-left: 37px;

        li {
          list-style: none;
          position: relative;

          &:before {
            content: ".";
            width: 12px;
            height: 12px;
            background: $timeline-color;
            color: transparent;
            position: absolute;
            top: calc(1.9vmin - 6px);
            left: -36px;
          }
        }
      }

      ul ul li:before {
        background: $timeline-color-black;
        width: 21px;
        height: 1px;
        top: calc(1.9vmin - 1px);
      }

      ul ul ul {
        padding-left: 29px;
        font-size: 2.4vmin;

        li:before {
          border-radius: 50%;
          width: 7px;
          height: 7px;
          line-height: 7px;
          top: calc(1.9vmin - 3.5px);
          left: -28px;
        }
      }

      ol {
        list-style: none;
        padding-left: 0;
        counter-reset: list1;

        li {
          position: relative;
          padding-left: 35px;

          &:before {
            position: absolute;
            counter-increment: list1;
            content: counter(list1) " ";
            left: 0;
          }
        }
      }

      ol ol {
        counter-reset: list2;

        li {
          position: relative;
          padding-left: 89px;

          &:before {
            position: absolute;
            counter-increment: list2;
            content: counter(list1) "." counter(list2);
            left: 35px;
          }
        }
      }

      ol ol ol {
        padding-left: 93px;
        counter-reset: list2;

        li {
          position: relative;
          padding-left: 25px;

          &:before {
            position: absolute;
            content: ".";
            border-radius: 50%;
            background: $timeline-color-black;
            min-width: 7px;
            height: 7px;
            top: calc(1.9vmin - 3.5px);
            left: -4px;
            color: transparent;
          }
        }
      }

      ol ol ol ol {
        padding-left: 0;
        counter-reset: list3;

        li {
          padding-left: 60px;
          position: relative;

          &:before {
            all: initial;
            position: absolute;
            counter-increment: list3;
            content: counter(list3);
            font: inherit;
            left: 24px;
          }
        }
      }

      ol ol ol ol ol {
        counter-reset: list3;
        padding-left: 36px;

        li {
          &:before {
            counter-increment: list3;
            content: counter(list3);
          }
        }
      }
    }
  }
}

.gallery-action-icon {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.5);
  background-position: center;
  background-size: 100%;
  background-repeat: no-repeat;

  &.prev-icon {

  }

  &.next-icon {

  }

  &.preview-icon {
    width: 25px;
    height: 25px;
    background-size: 85%;
    background-color: rgba(255, 255, 255, 0.7);
    position: relative;

    &.close-icon {
    }

    &.zoom-in-icon {
    }

    &.zoom-out-icon {
    }

    &.rotate-left-icon {
    }

    &.rotate-right-icon {
    }
  }
}

.select-filter-type-panel {
  max-height: 75% !important;
}

.select-filter-status-panel {
  max-width: 330px !important;
}

/*
TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.
*/
.mat-select-support {
  padding-top: 15px!important;
  padding-bottom: 15px!important;
}

svg.draw-svg-container {
  path.draggable {
    &:hover {
      stroke: #868080 !important;
      stroke-opacity: 1 !important;
    }
  }
}

.social-tooltip-panel {
  position: relative;
  overflow: visible !important;
  font-size: 12px;
  max-width: 120px  !important;
}

@media (max-width: 767px) {
  html, body {

  }

  .mat-mdc-menu-panel.mat-mdc-menu-panel.menu-sort-and-filter {
    max-width: 369px;
  }

  .mat-mdc-menu-panel.mat-mdc-menu-panel.desktop-create-event-wizard {
    display: block;
    pointer-events: all;
  }

  .select-filter-type-panel {
    max-height: 256px !important;
  }

  .editor.detail-mode {
    .html-text-editor-textarea {
      b {
        font-weight: bold;
      }

      &.read-only {
        ul {
          list-style: none;
          padding-left: 37px;

          li {
            list-style: none;
            position: relative;

            &:before {
              content: ".";
              width: 12px;
              height: 12px;
              background: $timeline-color;
              color: transparent;
              position: absolute;
              top: calc(2.9vmin - 6px);
              left: -36px;
            }
          }
        }

        ul ul li:before {
          background: $timeline-color-black;
          width: 21px;
          height: 1px;
          top: calc(2.9vmin - 1px);
        }

        ul ul ul {
          padding-left: 29px;
          font-size: 3.4vmin;

          li:before {
            border-radius: 50%;
            width: 7px;
            height: 7px;
            line-height: 7px;
            top: calc(2.2vmin - 3.5px);
            left: -28px;
          }
        }

        ol {
          list-style: none;
          padding-left: 0;
          counter-reset: list1;

          li {
            list-style: none;

            &:before {
              position: relative;
              counter-increment: list1;
              content: counter(list1) " ";
              top: 0;
              left: 0;
              margin-right: 10px;
            }
          }
        }

        ol ol {
          counter-reset: list2;

          li {
            padding-left: 57px;

            &:before {
              counter-increment: list2;
              content: counter(list1) "." counter(list2);
            }
          }
        }

        ol ol ol {
          padding-left: 93px;
          counter-reset: list2;

          li {
            position: relative;
            padding-left: 10px;

            &:before {
              position: absolute;
              content: ".";
              border-radius: 50%;
              background: $timeline-color-black;
              min-width: 7px;
              height: 7px;
              top: calc(2.9vmin - 3.5px);
              left: -17px;
              color: transparent;
            }
          }
        }

        ol ol ol ol {
          padding-left: 0;
          counter-reset: list3;

          li {
            position: relative;
            padding-left: 28px;

            &:before {
              all: initial;
              position: absolute;
              counter-increment: list3;
              content: counter(list3);
              font: inherit;
              left: 9px;
            }
          }
        }

        ol ol ol ol ol {
          counter-reset: list4;
          padding-left: 20px;

          li {
            padding-left: 33px;

            &:before {
              counter-increment: list4;
              content: counter(list3) "." counter(list4);
            }
          }
        }
      }
    }
  }
}

/* IE12+ styles */
@supports (-ms-ime-align:auto) {
  .mat-mdc-menu-panel.mat-mdc-menu-panel.ie-moods-menu-panel {
    overflow: hidden;
  }
  .mat-mdc-menu-panel.mat-mdc-menu-panel.send-content-message {
    overflow: hidden;
  }
}

.conference-settings-dialog .mat-mdc-dialog-container {
  border-radius: 20px;
}

.timeline-dialog-radius .mat-mdc-dialog-container {
  border-radius: 20px;
}

.timeline-dialog-padding {
  .mdc-dialog__surface {
    box-sizing: border-box;
    padding: 24px;
  }
}

mat-spinner[customSpinner] {
  svg {
    circle {
      stroke: white;
    }
  }
}

mat-spinner[tlSpinner] {
  svg {
    circle {
      stroke: $timeline-border-color-orange;
    }
  }
}

mat-spinner[tlAISpinner] {
  svg {
    circle {
      stroke: #CE44FF;
    }
  }
}

#textEditor,
.text-note-content {
  hotspot {
    background-color: $timeline-background-color-orange-02;
    cursor: pointer;
    position: relative;
    border-radius: 16px;
    &:hover {
      background-color: $timeline-table-row-hover;
    }
    &[data-incorrect] {
      text-decoration: $timeline-priority-color-red wavy underline;
      text-decoration-skip-ink: none;
    }
    &[data-audio_id]:not([data-text_annotation_id]):not([data-video_annotation_id]) {
      padding: 0 0 0 10px;
      &:before {
        content: attr(data-order_number);
        color: $timeline-border-color-orange;
        font-size: inherit;
        padding-right: 5px;
        font-weight: bold;
      }
      &:after {
        content: "▲";
        color: $timeline-border-color-orange;
        padding: 0 2px;
        transform: rotate(90deg);
        display: inline-block;
      }
      &[audio_is_playing="true"],
      &[video_is_playing="true"] {
        &:after {
          content: "■";
          padding: 0;
          margin: 0 12px 0 0;
          transform: rotate(-90deg);
        }
      }
    }
    &[data-text_annotation_id],
    &[data-video_annotation_id] {
      padding: 0 4px;
      &:not([data-audio_id]) {
        padding-right: 10px;
      }
      &:before {
        content: attr(data-order_number);
        color: $timeline-border-color-orange;
        font-size: inherit;
        padding: 0 5px;
        font-weight: bold;
      }
      &[data-audio_id],
      &[data-video_annotation_id] {
        &:after {
          content: "▲";
          color: $timeline-border-color-orange;
          padding: 0 2px;
          transform: rotate(90deg);
          display: inline-block;
        }
        &[audio_is_playing="true"],
        &[video_is_playing="true"] {
          &:after {
            content: "■";
            padding: 0;
            margin: 0 12px 0 0;
            transform: rotate(-90deg);
          }
        }
      }
    }
    &[data-audio_id] {
      &[data-text_annotation_id],
      &[data-vieo_annotation_id] {
        padding: 0 2px 0 4px;
        &:before {
          content: attr(data-order_number);
          color: $timeline-border-color-orange;
          font-size: inherit;
          padding: 0 5px;
          font-weight: bold;
        }

        &:after {
          content: "▲";
          color: $timeline-border-color-orange;
          padding: 0 2px;
          transform: rotate(90deg);
          display: inline-block;
        }

        &[audio_is_playing="true"],
        &[video_is_playing="true"] {
          &:after {
            content: "■";
            padding: 0;
            margin: 0 12px 0 0;
            transform: rotate(-90deg);
          }
        }
      }
    }
    &[data-loading] {
      &:before {
        content: "◐";
        animation: load 2s linear infinite;
      }
    }

    @keyframes load {
      33% { content: "◓" }
      66% { content: "◑" }
      100% { content: "◒" }
    }
  }
}

.column-range-line {
  position: relative;
  &::after {
    content: "";
    background-image: linear-gradient(to bottom, $timeline-border-color-orange 0%, $timeline-border-color-orange 33%, transparent 0%);
    background-size: 100% 4px;
    background-repeat: repeat-y;
    background-position: 0;
    height: 100%;
    width: 1px;
    position: absolute;
    right: -1px;
    z-index: 100;
  }
  &.warning {
    &::after {
      background-image: linear-gradient(to bottom, $timeline-color-error 0%, $timeline-color-error 33%, transparent 0%);
      width: 2px;
      z-index: 100000;
      right: -2px;
    }
  }
}
