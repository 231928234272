mat-slider[timeline] {
  --mdc-slider-inactive-track-height: 2px;
  --mdc-slider-active-track-height: 2px;
  mat-slider-visual-thumb {
    --mdc-slider-handle-height: 16px;
    --mdc-slider-handle-width: 16px;
    .mdc-slider__thumb-knob {
      background-color: $timeline-background-color-orange !important;
      border-color: $timeline-background-color-orange !important;;
    }
    .mat-ripple {
      display: none;
    }
  }
  .mdc-slider__track--active_fill {
    border-color: $timeline-border-color-orange;
  }
}
/*
mat-slider[tl-always-show-ticks=true] .mat-slider-wrapper {

  .mat-slider-ticks {
    opacity: 1;
    background-image: repeating-linear-gradient(to right, gray, gray 2px, transparent 0, transparent);
    height: 2px;
  }
}
*/
