@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500');
@font-face {
  font-family: 'timeline';
  src:  url('/assets/fonts/timeline/timeline.eot?2dh5l7');
  src:  url('/assets/fonts/timeline/timeline.eot?2dh5l7#iefix') format('embedded-opentype'),
  url('/assets/fonts/timeline/timeline.ttf?2dh5l7') format('truetype'),
  url('/assets/fonts/timeline/timeline.woff?2dh5l7') format('woff'),
  url('/assets/fonts/timeline/timeline.svg?2dh5l7#timeline') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="tl-icon-"], [class*=" tl-icon-"],
.timeline-icons {
  font-family: "timeline";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.tl-icon-break:before {
  content: "\e900";
}
.tl-icon-content:before {
  content: "\e901";
}
.tl-icon-question:before {
  content: "\e902";
}
.tl-icon-questionnaire:before {
  content: "\e903";
}
.tl-icon-summarise:before {
  content: "\e904";
}
.tl-icon-task:before {
  content: "\e905";
}
