
.mat-tooltip-multiline {
  white-space: pre-line;
}

.mat-tooltip-small {
  font-size: 10px;
}

.mat-tooltip-medium {
  font-size: 12px;
}

.mat-tooltip-large {
  font-size: 14px;
}

.mat-tooltip-network-quality {
  font-size: 12px;

  &.good {
    background-color: #00ae00 !important;
  }

  &.medium {
    background-color: #dba409 !important;
  }

  &.low {
    background-color: #d80202 !important;
  }
}

.mat-mdc-tooltip-panel {
  pointer-events: none;
}

.time-value-tooltip-timeline {
  white-space: pre-line;
  position: relative;
  top: 0;
  right: 10px;
  --mdc-plain-tooltip-container-color: #9d9d9d;
  border-radius: 4px;
  overflow: hidden;
  .mdc-tooltip__surface {
    text-align: left;
  }
}

.time-value-tooltip-timeline-module {
  white-space: pre-line;
  position: relative;
  top: 2px;
  right: 10px;
}

.tooltip-toolbar-right-button-fullscreen {
  position: absolute !important;
  width: max-content;
  top: -20px;
  right: -37px;
  &.pt {
    right: -39px;
  }
}
