
/* TODO(mdc-migration): The following rule targets internal classes of paginator that may no longer apply for the MDC version. */
mat-paginator {
  font-family: $timeline-font;
}

/* TODO(mdc-migration): The following rule targets internal classes of paginator that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of paginator that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of paginator that may no longer apply for the MDC version. */
mat-paginator[color=transparent] {
  &.mat-mdc-paginator {
    color: white;
    background-color: transparent;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  .mat-form-field-appearance-legacy .mat-form-field-underline {
    background-color: white;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
  .mat-mdc-paginator-page-size .mat-select-trigger,
  .mat-select-arrow,
  .mat-select-value {
    color: white;
  }
}
