@import 'variables';

$toolbar-container-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
$toolbar-right-button-box-shadow: 0 2px 1px -1px rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 1px 3px 0 rgba(0, 0, 0, .12);
$timeline-badge-box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);

[class^="icon-"][color=primary], [class*=" icon-"][color=primary],
.timeline-icons[color=primary] {
  color: $timeline-color;
}
[class^="icon-"][color=gray], [class*=" icon-"][color=gray],
.timeline-icons[color=gray] {
  color: $timeline-color-darkgray;
}

.timeline-color {
  color: $timeline-color;
}

.warning-color {
  color: $timeline-color-warn;
}

.font-size-08 {
  font-size: 0.8em;
}

.text-bold {
  font-weight: bold;
}

.menu-blur {
  filter: blur(25px);
}

[frame-line] {
  border: 2px solid transparent;
  margin: -2px;
}

[frame-line].cast {
  border: 2px solid orange;
}

[timeline-card] {
  display: flex;
  background-color: white;
  transform: translate(0px, 0px);
  padding-left: 10px;
  padding-right: 5px;
  border-radius: 4px;
}

[timeline-card].anchor {
  background-color: #fffaf1;
}

[timeline-card].noclick {
  cursor: default;
}

/*
[timeline-card]:hover {
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.175);
}
*/

[timeline-card-body] {
  align-self: center;
  padding: 20px 15px 20px 0;
  width: calc(100% - 50px);
  font-size: 14px;
}

[timeline-card-body].header-info {
  padding-top: 30px;
}

[timeline-card-body].draft {
  opacity: 0.5;
}

[timeline-content-body] {
  min-height: 55px;
}

[timeline-content-body].note-visible {
  min-height: 120px;
}

[timeline-content-body].text-content {
  display: flex;
}

[timeline-content-body].justify-center {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

[timeline-content-body].align-center {
  display: flex;
  align-items: center;
}

[timeline-card-side-menu] {
  cursor: default;
  margin-top: 15px;
}

[timeline-card-side-menu].break-timeline-card {
  margin-top: 0;
}

.border-cut {
  outline: 1px dashed gray;
}

.border-cut-last {
  border-bottom: 1px dashed gray;
}

.border-first {
  border-top: 1px solid transparent;
}

.border-cut-first {
  border-top: 1px dashed gray;
}

[side-menu-more-list] {
  height: 1px;
  position: relative;
  top: 0;
  left: 0;
}

[side-menu-more-list-note-active] {
  position: absolute;
  right: -55px;
  top: -9px;
}

[mat-button][side-menu-more-button] {
  display: flex;
  justify-content: center;
  height: 35px;
  width: 35px;
  min-width: 35px;
  margin-left: auto;
  background-color: transparent;
  color: black;
  cursor: pointer;
}

[side-menu-more-button][disabled] {
  opacity: 0.5;
}

[side-menu-more-button].message-count {
  color: gray;
}

[side-menu-more-button].moods {
  color: gray;
}

[side-menu-more-button].note {
  color: gray;
}

[side-menu-more-button].note.active {
  color: $timeline-color;
  position: absolute;
  right: -50px;
  top: -9px;
}

[side-menu-more-button].note.hidden {
  color: $timeline-color;
}

[side-menu-more-button].send-message {
  color: $timeline-color;
}

[side-menu-more-button].sort-filter-button {
  cursor: pointer;
  color: gray;
}

/*
TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.
*/
[side-menu-more-button]::ng-deep .mat-button-focus-overlay {
  background-color: transparent;
}

/*
TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.
*/
[side-menu-more-button].send-message::ng-deep .mat-button-ripple {
  border-radius: 50%;
}

/*
TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.
*/
[side-menu-more-button].action-menu-button::ng-deep .mat-button-ripple {
    border-radius: 50%;
}

/*
TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.
*/
[side-menu-more-button].action-user-menu-button::ng-deep .mat-button-ripple {
    border-radius: 50%;
}

[side-menu-more-button-icon] {
  top: 1px;
  position: absolute;
  left: 1px;
  font-size: 23px;
  width: 33px;
  height: 33px;
  line-height: 33px;
  text-align: center;
}

[side-menu-more-button-icon].message-count {
  font-size: 31px;
}

[side-menu-more-button-icon].moods {
  font-size: 25px;
}

[side-menu-more-button-icon].note {
  font-size: 31px;
}

[side-menu-more-button-icon].more-icon {
  font-size: 25px;
}

[side-left-panel] {
  display: flex;
  position: absolute;
}

[side-left-panel].bottom {
  bottom: 0;
  left: 0;
  z-index: 999999;
}

[mat-button][side-left-button] {
  display: flex;
  justify-content: center;
  height: 20px;
  width: 20px;
  min-width: 20px;
  margin-left: 0;
  background-color: transparent;
  color: black;
  cursor: pointer;
  color: gray;
  padding: 11px;
}

/*
TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.
*/
[side-left-button]::ng-deep .mat-button-focus-overlay {
  background-color: transparent;
}

/*
TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.
*/
[side-left-button]::ng-deep .mat-button-ripple {
  border-radius: 50%;
}

[side-left-button-icon] {
  top: 1px;
  position: absolute;
  left: 1px;
  font-size: 17px;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
}

[side-left-button-icon]:before {
  position: relative;
  left: 0;
  top: 1px;
}

[side-left-button-icon].cast {
  color: orange;
  top: 2px;
  left: 8px;
  cursor: default;
}

/*
TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.
*/
[side-left-button].draft::ng-deep .mat-button-ripple {
  display: none;
}

[side-left-button-icon].draft {
  top: 3px;
  left: 0;
}

[side-left-button-icon].private {
  top: 3px;
  left: 7px;
}

/*
TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.
*/
[side-left-button-icon].private::ng-deep .mat-button-ripple {
  display: none;
}


[side-left-button].user-logo {
  position: relative;
  width: 25px;
  height: 25px;
  min-width: 25px;
  left: 0;
  top: 2px;
  cursor: default;
}

[side-left-button-icon].user-logo {
  width: 25px;
  height: 25px;
  line-height: 25px;
  border-radius: 50%;
  left: 0;
  top: 0;
}

[side-left-button].back-to-sections {
  width: 25px;
  height: 25px;
  min-width: 25px;
  color: $timeline-color-level-1;
  margin-left: 15px;
  margin-right: 5px;
}

[side-left-button-icon].back-to-sections {
  width: 25px;
  height: 25px;
  line-height: 25px;
  border-radius: 50%;
  top: 0;
  left: 0;
  font-size: 25px;
}

[side-left-button].break-logo {
  width: 44px;
  height: 44px;
  min-width: 44px;
  cursor: default;
  background-color: $timeline-color-background;
  border-radius: 50%;
  margin-right: 10px;
  margin-left: 10px;
}

[side-left-button].break-logo.cover-down {
  background-color: $timeline-color-cover-down;
}

[side-left-button-icon].break-logo {
  width: 25px;
  height: 25px;
  line-height: 25px;
  font-size: 26px;
  top: 9px;
  left: 9px;
}

[side-left-button].mood-item {
  width: 35px;
  height: 35px;
  min-width: 36px;
  min-height: 36px;
  border: 2px solid white;
  box-sizing: border-box;
  cursor: pointer;
}

/*
TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.
*/
[side-menu-more-button]::ng-deep .mat-button-ripple {
  border-radius: 0;
}

[side-left-button].mood-item:hover {
  border: 2px solid gray;
}

[side-left-button].mood-item.select {
  border: 2px solid gray;
}

[side-left-button].mood-item.presenter {
  margin-bottom: 8px;
  cursor: default;
}

[side-left-button].mood-item.presenter:hover {
  border: 2px solid white;
}

[side-left-button-icon].mood-item {
  width: 30px;
  height: 30px;
  line-height: 35px;
}

[side-left-button].action-option {
  width: 52px;
  height: 32px;
  min-width: 52px;
  color: $timeline-color-level-1;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 2px;
  border-radius: 15px;
  border: 2px solid transparent;
}

[side-left-button].action-option.select {
  border: 2px solid $timeline-color-level-1;
  background-color: white;
}

[side-left-button].action-option:hover {
  transform: scale(1.15);
}

[side-left-button].action-option.select:hover {
  transform: scale(1);
}


[side-left-button][disabled].action-option:hover {
  transform: scale(1);
}

/*
TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.
*/
[side-left-button].action-option::ng-deep .mat-button-ripple {
  border-radius: 15px;
}

[side-left-button-icon].action-option {
  width: 25px;
  height: 25px;
  line-height: 27px;
  top: 1px;
  left: 11px;
  font-size: 25px;
}

[side-left-button-icon].action-option-img {
  width: 25px;
  height: 25px;
  line-height: 25px;
  top: 2px;
  left: 11px;
  font-size: 25px;
}

[side-left-button-icon].action-option.active {
  border: 2px solid orange;;
}

[side-left-button].change-time {
  width: 80px;
  min-width: 80px;
  cursor: default;
}

[side-left-button-icon].change-time {
  font-family: $timeline-font;
  color: $timeline-color-grey-l;
  font-size: 12px;
  width: 80px;
  min-width: 80px;
  text-align: left;
  margin-left: 8px;
  top: 5px;
}

[side-left-button-icon].change-time.cast {
  margin-left: 2px;
}

[moods-list] {
  display: flex;
  max-width: 144px;
  flex-wrap: wrap;
  padding-left: 5px;
  padding-right: 5px;
  overflow: hidden;
}

[moods-list=column-4] {
  max-width: 186px;
}

[relevancy] {
  background-color: #e6e6e6;
  display: flex;
  position: absolute;
  z-index: 100;
  width: 3px;
  height: 70px;
  right: 0;
  top: 10px;
  transform: rotate(180deg);
}

[relevancy] .process {
  background-color: limegreen;
  max-height: 100%;
  width: 3px;
}

[message-counter] {
  position: relative;
  top: -3px;
  font-size: 12px;
}

[mood-count] {
  font-size: 12px;
  color: black;
  line-height: 12px;
  position: relative;
  top: 20px;
  z-index: 100;
}

[timeline-text-field] {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  /* autoprefixer: ignore next */
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 14px;
}

[timeline-text-field].row-7 {
  -webkit-line-clamp: 7;
}

[timeline-text-field].row-7-align-center {
  -webkit-line-clamp: 7;
  align-self: center;
}

[timeline-text-field=row-2],
[timeline-text-field].row-2 {
  -webkit-line-clamp: 2;
}

[timeline-text-field].row-3 {
  -webkit-line-clamp: 3;
}

.text-line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  /* autoprefixer: ignore next */
  -webkit-box-orient: vertical;
  overflow: hidden;
  &.row-2 {
    -webkit-line-clamp: 2;
  }
  &.row-7 {
    -webkit-line-clamp: 7;
  }
}

[send-message-panel] {
  display: flex;
  flex-direction: column;
}

[last-2-messages] {
  margin-top: 5px;
  max-width: 290px;
}

[presentation-mode-messages] {
  margin-top: 5px;
  max-width: 290px;
  max-height: 290px;
  overflow-y: auto;
  overflow-x: hidden;
}

[input-message-panel] {
  display: flex;
  align-items: center;
}

[thumbnail-presenter] {
  width: 22px;
  min-width: 22px;
  height: 22px;
  background-color: $timeline-color;
  border-radius: 50%;
  align-self: flex-start;
}

[thumbnail-user] {
  width: 22px;
  min-width: 22px;
  height: 22px;
  border-radius: 50%;
  align-self: flex-start;
}

[chat-message] {
  display: flex;
  align-items: center;
  margin-top: 2px;
}

[user-message] {
  margin-left: 5px;
  font-size: 12px;
  color: gray;
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

[full-user-message] {
  margin-left: 5px;
  font-size: 12px;
  color: gray;
  display: block;
}

[input-message-text] {
  outline-color: $timeline-color-light;
  width: 260px;
}

[personal-note-mat-form-field] {
  width: 100%;
}

[personal-note-mat-form-field]::ng-deep .mat-mdc-form-field-infix {
  padding: 0 !important;
  padding-top: .84375em !important;
  border-top: 0 !important;
}

[personal-note-mat-form-field].qa-mode::ng-deep .mat-mdc-form-field-infix {
  padding-bottom: 6px !important;
}

[personal-note-mat-form-field]::ng-deep .mat-input-underline {
  display: none;
}

[personal-note-mat-form-field]::ng-deep .mat-mdc-form-field-wrapper {
  padding-bottom: .84375em;
}

[personal-note-mat-form-field].extended-comment::ng-deep .mat-mdc-form-field-label {
  top: 1.7em;
  color: rgba(0,0,0,.25)!important;
}

[personal-note-text] {
  overflow: hidden;
}

[personal-note-text].cover-down {
  // color: $timeline-color-green;
}

[personal-note-toolbar] {
  align-self: baseline;
  padding-top: 3px;
}

[extended-comment-toolbar] {
  align-self: baseline;
  padding-top: 3px;
  padding-right: 2px;
  margin-top: 32px;
  position: relative;
  left: 7px;
}

[switcher-down] {
  position: absolute;
  right: 6px;
  top: 7px;
}

/*
TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.
*/
[switcher-down]::ng-deep .mat-slide-toggle-ripple {
  display: none;
}

[input-message-text].multirow {
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  resize: none;
  max-height: 90px;
  height: 90px;
  min-height: 90px;
  color: black;
}

[personal-note-panel] {
  background-color: #e3f1fc;
  color: $timeline-color;
  display: flex;
  padding-left: 12px;
  padding-right: 12px;
  font-size: 14px;
  align-items: center;
  margin-top: 10px;
  transform: translate(0px, 0px);
  cursor: text;
  border-radius: 10px;
}

[personal-note-panel].edit-mode {
  align-items: flex-start;
  min-height: 80px;
}

[personal-note-panel].extended-comment {
  width: calc(100% + 28px);
  border-radius: 15px;
  margin-bottom: 5px;
}

[personal-note-panel].extended-comment.simple-user-mode {
  width: calc(100% + 20px);
}

[personal-note-panel].extended-comment.paginator-mode {
  width: calc(100% - 44px);
  border-radius: 15px;
  margin-bottom: 20px;
  margin-left: 10px;
  flex: none;
}

/*
TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.
*/
[personal-note-panel].extended-comment::ng-deep .mat-form-field-infix {
  overflow-x: hidden;
  overflow-y: hidden;
}

[personal-note-panel].extended-comment.cover-down {
  // background-color: $timeline-color-cover-down;
}

[personal-note-field] {
  display: block;
}

[progress-bar] {
  width: 100px;
  height: 5px;
}

[anchor] {
  display: none;
  width: 100%;
  height: 0;
}

::ng-deep mat-form-field.mat-focused.no-underline .mat-mdc-form-field-underline .mat-form-field-ripple,
::ng-deep mat-form-field.no-underline .mat-form-field-underline {
  background-color: transparent;
  opacity: 0;
}

::ng-deep mat-form-field.mat-focused.qa-edit-underline .mat-mdc-form-field-underline .mat-form-field-ripple {
  bottom: 20px;
  width: 100%;
}
::ng-deep mat-form-field.qa-edit-underline .mat-mdc-form-field-underline {
  bottom: 20px;
  width: calc(100% - 28px);
}

::ng-deep mat-form-field.qa-edit-underline .mat-mdc-form-field-label-wrapper {
  padding-top: 0;
  /*
TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.
*/
  .mat-form-field-label {
    top: 14px !important;
  }
}

::ng-deep mat-form-field.mat-focused.black-underline .mat-mdc-form-field-underline .mat-form-field-ripple,
::ng-deep mat-form-field.black-underline .mat-form-field-underline {
  background-color: white;
}

::ng-deep mat-form-field.mat-focused.black-underline .mat-mdc-form-field-label {
  color: white;
}

.panel-separator {
  width: 100%;
  height: 7px;
}

.registration-new {
  color: lightgray;
}
.not-attending,
.not-registered {
  color: gray;
}
.waiting-for-approval,
.waiting-for-event-approval {
  color: orange;
}
.on-waiting-list {
  color: darkgoldenrod;
}
.registration-confirmed {
  color: green;
}
.special-registration,
.registration-completed {
  color: $timeline-color-registration-completed;
}
.register-continue-incomplete {
  color: #610000;
}

.add-group-dialog {
  width: 640px;
}

.mat-mdc-menu-panel.user-menu-style {
  max-height: 450px;
}

.mat-mdc-menu-panel.manage-time-wizards {
  max-width: 450px;
}

.mat-mdc-menu-panel {
  min-height: 46px !important;
}

.page-layout {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 100%;

  position: relative;
  overflow: hidden;

  flex: 1 1 auto;

  height: 100%;
}

.page-layout .top-toolbar {
  background-color: gray !important;
  color: white;
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  left: 0;
  height: 200px;
}

.page-layout .page-container {
  flex: 1 1 auto;

  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 2;
  padding:  0 24px;
  width: 100%;
  min-width: 0;
  max-width: 100%;
  height: 100%;
  max-height: 100%;

  box-sizing: border-box;
}

.page-layout .header-container {
  background-color: gray !important;
  color: white;
  height: 134px;
  min-height: 134px;
  max-height: 134px;
  z-index: 2;
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
  place-content: center space-between;
  align-items: center;
}

.page-layout .content-container {
  background: #fff;
  border-radius: 8px;
  box-sizing: border-box;
  margin-bottom: 24px;

  flex: 1 1 auto;

  display: flex;
  flex-direction: column;
  overflow: hidden;
  box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
}

.page-layout .content-container .mat-mdc-table {
  background-color: white;
  position: relative;
  overflow: auto;
  flex: 1 1 auto;
  border-bottom: 1px solid rgba(0,0,0,.12);
  color: rgba(0,0,0,.87);
  &.user-table {
    margin: 0;
  }
}

.page-layout .mat-mdc-table .mat-mdc-header-row {
  min-height: 64px;
  width: 100%;
}

.page-layout .mat-mdc-table .mat-mdc-row {
  position: relative;
  height: 64px;
  cursor: pointer;
  width: 100%;
}

.page-layout .mat-mdc-table .mat-mdc-row:hover {
  background-color: $timeline-color-table-background-hover;
}

.page-layout .main-container {
  height: 100%;
  display: grid;
  grid-template-rows: 138px 64px minmax(200px, 1fr);
}

.page-layout .user-table {
  overflow: auto;
  margin: 2px;
  height: calc(100% - 64px);
}

.page-layout .header {
  height: 136px!important;
  min-height: 136px!important;
  max-height: 136px!important;
  box-sizing: border-box;
}

.page-layout .header .div {
  box-sizing: border-box;
}

.page-layout .avatar {
  width: 40px;
  min-width: 40px;
  height: 40px;
  line-height: 40px;
  margin: 0 8px 0 0;
  border-radius: 50%;
  font-size: 17px;
  font-weight: 600;
  text-align: center;

  color: rgba(0,0,0,.87);
}

.page-layout .column-id {
  flex: 0 1 256px;
}

.page-layout .column-actions {
  flex: 0 1 128px;
}

.page-layout .column-logo,
.page-layout .column-checkbox {
  flex: 0 1 64px;
}

.page-layout .column-default {
  flex: 1 0 100px;
}

.uppy-hidden-trigger {
  position: absolute;
  left: 0;
  top: 0;
  display: none;
  width: 0;
  height: 0;
}

.extended-comment-text {
  width: calc(100% - 28px);
}

.state-icon {
  width: 18px;
  height: 22px;
  min-width: 18px;
  min-height: 22px;
  line-height: 22px;
  background-position: center;
  position: relative;
  opacity: 0.6;
  &.draft {
    top: 1px;
  }
  &.public {
    top: 2px;
  }
  &.public-by-link {
    top: -1px;
  }
  &.private {
    top: 1px;
  }
}

.toolbar-right-button {
  width: 3em;
  min-height: 3em;
  margin-top: 0.6em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  cursor: pointer;
  color: $toolbar-right-button-color;
  background: $timeline-color-white;
  box-sizing: border-box;
  box-shadow: $toolbar-right-button-box-shadow;
  span {
    position: absolute;
    &.more-100 {
      font-size: 0.7em;
    }
    &.more-1000 {
      font-size: 0.5em;
    }
  }
  mat-icon {
    color: $toolbar-right-button-icons-color;
    .personal-pin-decor {
      background-color: $toolbar-right-button-icons-color;
    }
  }
  .material-icons {
    color: $toolbar-right-button-icons-color;
  }
  &.not-interactive {
    cursor: unset;
  }
  &.disabled {
    pointer-events: none;
  }
  &.orange {
    background-color: orange;
  }
  &:not(.not-interactive):hover {
    transform: scale(1.1);
  }

  .mat-mdc-button-persistent-ripple {
    border-radius: 40% !important;
  }
}

@media (max-width: 767px) {
  .toolbar-right-button {
    margin-top: 0;
    margin-left: 5px;
  }
}

@for $i from 1 through 30 {
  @for $j from 1 through 3 {
    .cell-#{$i}-#{$j} {
      grid-row: $i;
      grid-column: $j;
    }
  }
}


.snack-bar-warning {
  background-color: $timeline-color-orange;
  .mat-mdc-simple-snack-bar {
    span {
      color: $timeline-color-white;
    }
    .mat-mdc-snack-bar-action {
      button {
        background-color: $timeline-color-dark-darkgray;
        color: $timeline-color-white;
      }
    }
  }
}

.pdf-fit-to-screen {
  ::ng-deep .pdf-viewer-container {
    overflow: hidden;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

.toolbar-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: black;
  background-color: white;
  border-radius: 20px;
  padding-left: 5px;
  padding-right: 5px;
  box-sizing: border-box;
  box-shadow: $toolbar-container-box-shadow;
  cursor: pointer;
  height: 100%;
  .tc-logo-color {
    width: 100px;
    height: 100%;
    border-radius: 18px;

    &:hover {
      //cursor: pointer;
    }
  }

  .tc-logo-image {
    width: 100px;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 18px;

    &:hover {
      //cursor: pointer;
    }
  }

  .tc-name {
    text-align: start;
    padding: 0 16px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 700;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    /* autoprefixer: ignore next */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    &:hover {
      //cursor: pointer;
    }
  }
}

app-user-avatar.follow-me-manager-icon {
  ngx-avatar {
    .avatar-container {
      border: 1px solid $background-current-line-color-light;
      border-radius: 50%;
    }
  }
}

button[focus-overlay-off].mat-mdc-icon-button {
  /*
TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.
*/
  .mat-button-focus-overlay {
    opacity: 0!important;
  }
}

.gap-select {
  font-size: 1em;
  color: $timeline-color-black;
  cursor: pointer;
  border: none;
  border-radius: 10px;
  background-color: $timeline-content-scroller-background;
  outline: unset;
  caret-color: $timeline-color-black;
  background-repeat: no-repeat;
  background-position-x: right;
  background-position-y: center;
  appearance: none;
  padding-left: 5px;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24' fill='orangered'><path d='M0 0h24v24H0z' fill='none'/><path d='M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z'/></svg>");
  .gap-option {
    background-color: $timeline-color-white;
  }
  &.readonly {
    background-image: none;
  }
  &.correct {
    background-image: none;
    background-color: $timeline-color-green-l;
    color: $timeline-color-white;
  }
}

.gap-filling-result {
  width: 60px;
  height: 0.5em;
  display: inline-block;
  background-color: $timeline-event-prep-wrap-line;
  overflow: hidden;
  border-radius: 3px;
  .correct-result {
    height: 50%;
    background-color: $timeline-color-green;
  }
  .error-result {
    height: 50%;
    background-color: $timeline-color-warn;
  }
}

.gap-text-select {
  background-repeat: no-repeat;
  background-position-x: right;
  background-position-y: center;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24' fill='orangered'><path d='M0 0h24v24H0z' fill='none'/><path d='M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z'/></svg>");
}

.timeline-checkbox-gap-editor {
  font-size: 0.9em;
  position: relative;
  top: 0.11em;
  height: auto;
  width: 0.8em;
  &[type=checkbox]:after {
    content: attr(value);
    margin: -4px 0;
    vertical-align: top;
    white-space: nowrap;
    display: inline-block;
    border: 1px solid $timeline-progress-not-used-background-color;
    position: relative;
    left: -5px;
    padding: 1px 4px 1px 20px;
    line-height: 1.2;
    border-radius: 8px;
  }
}

.timeline-checkbox-gap-label {
  border-radius: 8px;
  padding: 0 4px 0 1px;
  border: 1px solid $timeline-progress-not-used-background-color;
  &.correct {
    background-color: $timeline-color-green-l;
    color: $timeline-color-white;
  }
}
.timeline-checkbox-gap {
  font-size: 0.9em;
  position: relative;
  top: 0.11em;
  height: 1em;
  width: 0.8em;
}

.checkbox-gap-filling-result {
  font-size: 0.8em;
  height: 1em;
  width: 1em;
  padding: 0 0.3em;
  margin: 0 0.11em;
  background-color: $background-current-line-color;
  color: $timeline-color-white;
  border-radius: 8px;
  text-align: center;
  position: relative;
  top: -0.1em;
}

.multilingual-element {
  &.overflow {
    overflow-y: auto;
    height: 100% !important;
    &::-webkit-scrollbar {
      background-color: $timeline-content-editor-track-piece;
      width: 5px;
      height: 5px;
    }
    &::-webkit-scrollbar-track {
      background-color: $timeline-content-editor-track-piece;
    }
    &::-webkit-scrollbar-track-piece {
      background-color: $timeline-color-warn;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $timeline-color-true-red;
    }
    // FireFox scrollbar style
    @supports (not selector(::-webkit-scrollbar)) {
      scrollbar-width: thin;
    }
  }
}

mat-header-cell[timeline-sort-header] {
  &.mat-sort-header {
    .mat-sort-header-container {
      .mat-sort-header-arrow {
        position: absolute;
        right: 0;
      }
    }
  }
}

mat-header-cell[timeline-sort-header][sort-arrow-right15] {
  &.mat-sort-header {
    .mat-sort-header-container {
      .mat-sort-header-arrow {
        position: absolute;
        right: -15px;
      }
    }
  }
}

[timeline-v-scrollbar] {
  &::-webkit-scrollbar {
    background-color: $timeline-color-white;
    width: $scrollbar-width;
  }
}

[timeline-h-scrollbar] {
  &::-webkit-scrollbar {
    background-color: $timeline-color-white;
    height: $scrollbar-width;
  }
}

[timeline-v-scrollbar],
[timeline-h-scrollbar]{
  &::-webkit-scrollbar-track {
    background-color: $timeline-color-white;
  }
  &::-webkit-scrollbar-track-piece {
    background-color: $timeline-content-editor-track-piece;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $timeline-content-editor-scrollbar-thumb;
  }
  // FireFox scrollbar style
  @supports (not selector(::-webkit-scrollbar)) {
    scrollbar-width: thin;
  }
}

[timeline-v-scrollbar=white] {
  &::-webkit-scrollbar-track-piece {
    background-color: $timeline-color-white;
  }
}

[timeline-v-scrollbar=width-2px] {
  &::-webkit-scrollbar {
    width: 2px;
  }
}

.timeline-tooltip {
  white-space: pre-line;
  cursor: default;
}

[text-truncate-by-ellipsis] {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

/* styles for mobile device */
@media (max-width: 767px)  {
  .page-layout .mat-mdc-table .mat-mdc-header-row,
  .page-layout .mat-table .mat-mdc-row {
    width: unset;
  }

  [personal-note-panel].extended-comment.paginator-mode {
    width: calc(100% - 90px);
    right: 59px;
  }

  .extended-comment-text {
    width: calc(100% - 28px);
  }
}

/* FireFox and other styles */
@supports not (-webkit-line-clamp: 1) {
  [timeline-text-field] {
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

/* IE11 styles */
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  [message-counter] {
    top: -3px;
    left: -9px;
    padding-left: 4px;
  }
}

/* IE12+ styles */
@supports (-ms-ime-align:auto) {

}

/* Edge styles */
@supports not (word-break: break-word) {

}

/* Safari 11+ */
@media not all and (min-resolution: .001dpcm) {
  @supports (-webkit-appearance:none) and (stroke-color:transparent) {
    [input-message-text] {
      font-size: 16px;
    }

    [input-message-text].multirow {
      font-size: 16px;
    }
  }
}

i[rotate90] {
  transform: rotate(90deg);
}

.ai-gradient {
  background: linear-gradient(125.14deg, #FC7723 16.52%, #CE44FF 81.82%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  &.weight-700 {
    font-weight: 700;
  }
}
