.mat-snackbar-config-error {
  color: $timeline-color-black;
  background-color: $timeline-color-red;
}

.mat-snackbar-config-error .mat-mdc-snack-bar-action {
  color: $timeline-color-black;
}

$toaster-configs : (
  info  : #2f96b4,
  success : #51a351,
  warning : #f89406,
  error : #bd362f,
) !default;

@mixin make-toaster-config() {
  @each $attr-name, $attr-value in $toaster-configs {
    mat-snack-bar-container.toaster-#{$attr-name} {
      margin-top: 84px !important;
      opacity: 0.9 !important;
      color: $timeline-color-white;
      --mdc-snackbar-container-color: #{$attr-value};
      &.mat-mdc-snack-bar-container {
        max-width: 344px;
      }
    }
  }
}

@include make-toaster-config();
