@import 'public/sass/variables';
@import './public/sass/settings';
@import './public/sass/helpers';
@import './public/sass/timeline';
@import './public/sass/material';
@import "@angular/material/prebuilt-themes/indigo-pink.css";
@import "../node_modules/@uppy/core/dist/style.min.css";
@import "../node_modules/@uppy/dashboard/dist/style.min.css";
@import "bootstrap/index";
@import "bootstrap/import";

[id^="uppy-Dashboard-"] {
  .uppy-Dashboard-note {
    white-space: pre-wrap;
    max-width: 300px;
  }
}
