.timeline .mat-bottom-sheet-container {
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.recorder-bottom-sheet {
  position: fixed !important;
  min-width: 270px;
  width: calc(25% - 4px);
  left: 16px;

  .mat-bottom-sheet-container-medium, .mat-bottom-sheet-container-large, .mat-bottom-sheet-container-xlarge {
    min-width: calc(25% - 4px);
  }

  .mat-bottom-sheet-container {
    max-height: 100vh !important;
    width: 100%;
  }
}

.recorder-backdrop {
  opacity: 0;
}
