.tl-flex {
  @include display-flex();
}

.tl-inline-flex {
  @include display-flex($fb-display: inline-flex);
}

.tl-flex-direction-row {
  @include display-flex();
  @include flexDirection($fb-direction: row);
}

.tl-flex-direction-row-reverse {
  @include display-flex();
  @include flexDirection($fb-direction: row-reverse);
}

.tl-flex-direction-column {
  @include display-flex();
  @include flexDirection($fb-direction: column);
}

.tl-flex-direction-column-reverse {
  @include display-flex();
  @include flexDirection($fb-direction: column-reverse);
}

.tl-flex-wrap {
  @include display-flex();
  @include flexWrap($fb-wrap: wrap);
}

.tl-flex-nowrap {
  @include display-flex();
  @include flexWrap();
}

.tl-flex-wrap-reverse {
  @include display-flex();
  @include flexWrap($fb-wrap: wrap-reverse);
}

.tl-justify-content-center {
  @include display-flex();
  @include flexJustifyContent($fb-justify-content: center);
}

.tl-justify-content-start {
  @include display-flex();
  @include flexJustifyContent($fb-justify-content: flex-start);
}

.tl-justify-content-end {
  @include display-flex();
  @include flexJustifyContent($fb-justify-content: flex-end);
}

.tl-justify-content-between {
  @include display-flex();
  @include flexJustifyContent($fb-justify-content: space-between);
}

.tl-justify-content-around {
  @include display-flex();
  @include flexJustifyContent($fb-justify-content: space-around);
}

.tl-justify-content-evenly {
  @include display-flex();
  @include flexJustifyContent($fb-justify-content: space-evenly);
}

.tl-justify-content-stretch {
  @include display-flex();
  @include flexJustifyContent($fb-justify-content: stretch);
}

.tl-align-content-center {
  @include display-flex();
  @include flexAlignContent($fb-align-content: center);
}

.tl-align-content-start {
  @include display-flex();
  @include flexAlignContent($fb-align-content: flex-start);
}

.tl-align-content-end {
  @include display-flex();
  @include flexAlignContent($fb-align-content: flex-end);
}

.tl-align-content-between {
  @include display-flex();
  @include flexAlignContent($fb-align-content: space-between);
}

.tl-align-content-around {
  @include display-flex();
  @include flexAlignContent($fb-align-content: space-around);
}

.tl-align-content-evenly {
  @include display-flex();
  @include flexAlignContent($fb-align-content: space-evenly);
}

.tl-align-content-stretch {
  @include display-flex();
  @include flexAlignContent($fb-align-content: stretch);
}

.tl-justify-items-center {
  @include display-flex();
  @include flexJustifyItems($fb-justify-items: center);
}

.tl-justify-items-start {
  @include display-flex();
  @include flexJustifyItems($fb-justify-items: flex-start);
}

.tl-justify-items-end {
  @include display-flex();
  @include flexJustifyItems($fb-justify-items: flex-end);
}

.tl-justify-items-baseline {
  @include display-flex();
  @include flexJustifyItems($fb-justify-items: baseline);
}

.tl-justify-items-stretch {
  @include display-flex();
  @include flexJustifyItems($fb-justify-items: stretch);
}

.tl-align-items-center {
  @include display-flex();
  @include flexAlignItems($fb-align-items: center);
}

.tl-align-items-start {
  @include display-flex();
  @include flexAlignItems($fb-align-items: flex-start);
}

.tl-align-items-end {
  @include display-flex();
  @include flexAlignItems($fb-align-items: flex-end);
}

.tl-align-items-baseline {
  @include display-flex();
  @include flexAlignItems($fb-align-items: baseline);
}

.tl-align-items-stretch {
  @include display-flex();
  @include flexAlignItems($fb-align-items: stretch);
}

.tl-flex-grow-1 {
  @include flexGrow();
}

.tl-flex-grow-2 {
  @include flexGrow($fb-flex-grow: 2);
}

.tl-flex-grow-3 {
  @include flexGrow($fb-flex-grow: 3);
}
