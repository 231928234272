@each $prop, $abbrev in (width: tl-w, height: tl-h) {
  @each $size, $length in $sizes {
    .#{$abbrev}-#{$size} {
      #{$prop}: $length !important;
    }
  }
}

[class*="tl-"] *,
[class*="tl-"] *::before,
[class*="tl-"] *::after,
.tl-border-box {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.tl-overflow-hidden {
  overflow: hidden !important;
}

.tl-border-0 {
  border: 0 !important;
}

.tl-table {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;

  th, td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
  }

}

.tl-table-borderless > tbody > tr > td,
.tl-table-borderless > tbody > tr > th,
.tl-table-borderless > tfoot > tr > td,
.tl-table-borderless > tfoot > tr > th,
.tl-table-borderless > thead > tr > td,
.tl-table-borderless > thead > tr > th {
  border: none;
}
