@use "sass:math";

@mixin clearfix() {
  &:before,
  &:after {
    display: table;
    content: " ";
  }
  &:after {
    clear: both;
  }
}

@mixin container-fixed($gutter: $grid-gutter-width) {
  padding-right: ceil(math.div($gutter, 2));
  padding-left: floor(math.div($gutter, 2));
  margin-right: auto;
  margin-left: auto;
  @include clearfix;
}
