.mat-mdc-menu-panel.mat-mdc-menu-panel {
  font-family: $timeline-font;
  max-width: var(--mat-mdc-menu__panel-max-width, 280px);
}

.mat-menu-center-before {
  transform: translateX(50%);
}

.mat-menu-center-after {
  transform: translateX(-50%);
}

.mat-mdc-menu-panel.mat-mdc-menu-panel {
  &.p-1em {
    padding: 8px 1em;
    box-sizing: border-box;
  }
  &.plr-1em {
    padding: 0 1em;
    box-sizing: border-box;
  }
  &.reset-max-width {
    max-width: initial;
  }
  &.p-y-0 {
    .mat-mdc-menu-content {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
  &.max-width-unset {
    max-width: unset;
  }
  &.max-width-fit-content {
    max-width: fit-content;
  }
  &.dark-panel-rounded-10 {
    background: $timeline-free-slot-background;
    border-radius: 10px;

    .mat-mdc-menu-item {
      .mat-mdc-menu-item-text {
        color: $timeline-color-white;
      }

      &:hover {
        background-color: $timeline-free-slot-background-hover-color;
      }
      .ti,
      .mat-icon {
        color: $timeline-color-white !important;
      }
    }
  }
}

.mat-mdc-menu-panel.mat-mdc-menu-panel.dark-theme {
  .mat-mdc-menu-content {
    padding: 0;
  }
  button {
    &.menu-title-item {
      font-weight: bold;
      text-align: center;
      background-color: lightgray;
      cursor: default;
    }
  }
}

.mat-mdc-menu-panel.mat-mdc-menu-panel.black-theme {
  background: $timeline-color-black;
  span, mat-icon {
    color: #FFF;
  }
}

.mat-mdc-menu-panel.mat-mdc-menu-panel.main {
  background: white;
  border-radius: 20px;

  margin-top: 10px;
  margin-right: -4px;

  max-width: 550px;

  .cdk-overlay-pane {
  }

  &::-webkit-scrollbar {
    width: 0;
  }

  &.three-in-row {
    max-width: max-content;
  }

  .mat-mdc-menu-content {
    padding: 16px;
    box-sizing: border-box;
  }
  .row {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 6px;
    &.title {
      text-transform: uppercase;
      font-size: 20px;
      color: $timeline-content-scroller-active-content;
      padding-left: 6px;
    }
    &.line {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
  }
  .row:not(:nth-last-child(1)) {
    padding-bottom: 16px;
    box-sizing: border-box;
  }
  .menu-button {
    z-index: 10000;
    width: 110px;
    height: 110px;
    box-sizing: border-box;
    white-space: normal;
    border-radius: 24px;
    background-color: $timeline-menu-button-background;
    //margin: 6px;
    padding: 0 11px;
    border: 1px solid transparent;
    &:hover {
      border: 1px solid $timeline-background-color;
    }
    .data-container {
      display: grid;
      grid-template-rows: 1fr 1fr;
      height: 100%;
      i.ti,
      mat-icon {
        margin: auto auto 0 auto;
        color: $toolbar-right-button-icons-color;
      }
      div {
        line-height: 15px;
        padding-top: 5px;
        box-sizing: border-box;
        color: $toolbar-right-button-color;
      }
    }
    &.my-time-button {
      padding: 0;
    }
    &.delete-button {
      color: rgb(205, 142, 143);
      &:hover {
        color: $timeline-color-white;
        background-color: $timeline-color-red;
        .data-container {
          i.ti {
            color: $timeline-color-white-08;
          }
        }
      }
    }
  }

  .menu-line-button {
    line-height: 17px;
    margin: 3px 6px 3px 0;
    padding-left: 6px;
    display: flex;
    justify-content: flex-start;
    border-radius: 16px;
    .data-container {
      display: flex;
      align-items: center;
      padding: 3px 0;
      i.ti,
      mat-icon {
        color: $toolbar-right-button-icons-color;
      }
      span, div {
        padding-left: 8px;
        box-sizing: border-box;
        color: $toolbar-right-button-color;
      }
    }
    &.terms-privacy-button {
      float: right;
      min-width: 100px;
      min-height: 50px;
      max-height: 50px;
      background-color: transparent !important;
      border: 0;
      color: white;
      cursor: pointer;
      margin: 0;
      &:focus {
        outline: none;
      }
    }
    &.delete-button {
      color: rgb(205, 142, 143);
      &:hover {
        color: $timeline-color-white;
        background-color: $timeline-color-red;
        .data-container {
          i.ti {
            color: $timeline-color-white-08;
          }
        }
      }
    }
  }
}

.mat-mdc-menu-panel.mat-mdc-menu-panel.timeline {
  border-radius: 16px;
  &.language-translate-statistic {
    min-width: 440px;
    max-width: unset;
  }

  &.audience-menu {
    max-height: 450px;
    max-width: 350px;
  }

  &.timeline-section-menu {
    max-width: 315px;
    &.one-item {
      min-height: 45px;
    }
  }

  &.lightly-dark {
    background-color: $timeline-menu-background-color;
  }

  &.quiz-question-toolbar-menu {
    --mat-mdc-menu__panel-max-width: fit-content;
  }
}

[side-menu-item].mat-mdc-menu-item {
  --mdc-typography-body1-letter-spacing: normal;
  --mdc-typography-body1-font-size: 16px;
  --mdc-typography-body1-line-height: 30px;
  --mat-menu-item-spacing: 10px;
  --mat-menu-item-icon-size: 5px;

  &:has(.material-icons,mat-icon,[matButtonIcon],i.ti) {
    padding-right: 20px;
    padding-left: 5px;
  }

  font-family: $timeline-font;
  height: 30px;
  min-height: 30px;
  min-width: 145px;

  .mat-mdc-menu-item-text {
    white-space: var(--mat-mdc-menu-item-text__white-space, nowrap) !important;
    overflow: hidden;
    text-overflow: ellipsis;
    display: var(--mat-mdc-menu-item-text__display, block);
  }

  &.clipboard-history-menu-item {
    padding-right: 5px !important;

    &:hover {
      z-index: 1;
    }

    .delete-clipboard-history-menu-item {
      min-width: 30px;
      width: 30px;
      padding: 0;
    }

    .disabled-hover {
      &:hover {
        background-color: transparent;
      }
    }

    .mat-mdc-menu-item-text {
      display: flex;
      align-items: center;
      width: 100%;
    }
  }
  &.component-menu-button {
    .mat-mdc-menu-item-text {
      display: flex;
      align-items: center;
      width: 100%;
    }
  }

  i.ti,
  .mat-icon {
    top: 0;
    margin: 0;
    position: relative;
    line-height: 30px;
    padding-bottom: 3px;
    left: 0;
    font-size: 16px;
    width: 30px;
    height: 30px;
    text-align: center;
    color: $timeline-menu-icon-color;
    &.disable {
      color: $timeline-background-color-switch-off;
    }
  }
  i.ti {
    margin-right: -5px;
    display: inline-block;
  }

  .close-icon {
    color: $timeline-background-color-dark;
  }

  &.caption-ellipsis {
    .mat-mdc-menu-item-text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
    }
  }

  &.cdk-focused {
    background-color: transparent !important;
    &:hover {
      background-color: rgba(0, 0, 0, 0.04) !important;
    }
  }
}

[side-menu-item].mat-mdc-menu-item {
  .mat-icon[orange] {
    color: #f19e00;
  }
}

.mat-mdc-menu-panel.mat-mdc-menu-panel.mat-menu-timeline-filter {
  border-radius: 8px;
  background-color: $timeline-toolbar-button-background;
  .mat-mdc-menu-content {
    padding: 16px 16px 10px 16px;
    min-width: 100px;
    max-width: 165px;
    min-height: 70px;
    overflow: hidden;
  }
  &.my-knowledge,
  &.event-dashboard {
    background-color: $timeline-menu-background;
    .mat-mdc-menu-content {
      padding: 8px 0;
    }
  }
  &.event-dashboard {
    border-radius: 16px;
    .mat-mdc-menu-content {
      max-width: 255px;
    }
  }
}

.mat-mdc-menu-panel.color-picker-menu {
  border-radius: 20px;
  box-shadow: 4px 4px 15px 0px #0000001A;
}
