mat-form-field.mat-mdc-form-field {
  font-family: $timeline-font;
  letter-spacing: normal;
}

mat-form-field[timeline],
mat-form-field.timeline {
  width: 100%;
  box-sizing: border-box;
  --mdc-filled-text-field-focus-active-indicator-color: black;
  .mdc-text-field {
    padding: 0;
    // text field
    &:not(.mdc-text-field--disabled) .mdc-text-field__input {
      color: $timeline-color-black;
    }

    .mdc-text-field__input {
      &.error {
        caret-color: var(--mdc-theme-error, #f44336);
      }
    }

    &.mat-mdc-text-field-wrapper {
      background-color: unset;

      .mat-mdc-form-field-focus-overlay {
        background-color: unset;
      }
      .mat-mdc-form-field-flex {
        line-height: 1.125;
        .mat-mdc-floating-label {
          top: 18px;
          transition: transform 350ms cubic-bezier(0.4, 0, 0.2, 1),color 350ms cubic-bezier(0.4, 0, 0.2, 1);
          &.mdc-floating-label--float-above {
            transform: translate(0, -90%) scale(0.75);
          }
        }
      }
      .mat-mdc-form-field-infix {
        // padding: 1.1em 0 0.4375em 0;
        padding: 0 0 0.4375em 0;
        min-height: 1.2em;
        display: var(--mat-mdc-form-field-infix__display, initial);
        justify-content: var(--mat-mdc-form-field-infix__justify-content, initial);
        width: 100%;
        .mat-mdc-input-element {
          min-height: var(--mat-mdc-input-element__min-height, 1.5em);
        }
      }
      .mdc-line-ripple {
        &:before,
        &:after {
          bottom: 4px;
        }
        &:before {
          border-bottom-color: var(--mat-mdc-form-field_underline-color, rgba(0, 0, 0, 0.42));
        }
      }
    }
    .mdc-floating-label {
      transform: translateY(20%);
    }

    &.mdc-text-field--disabled {
      .mdc-line-ripple {
        &::before {
          background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
          background-size: 4px 100%;
          background-repeat: repeat-x;
          background-position: 0;
          height: 1px;
          border-bottom-style: none;
        }
      }
    }
  }

  // not text field
  .mat-mdc-form-field-wrapper {
    padding: 0;
    .mat-mdc-form-field-flex {
      .mat-mdc-form-field-infix {
        border: 0;
        .mat-mdc-select-trigger {
          height: unset;
        }
      }
      .mat-mdc-form-field-label-wrapper {
        display: none;
      }
    }
    .mat-mdc-form-field-underline {
      display: none;
    }
  }

  .mat-mdc-form-field-input-control.mat-mdc-form-field-input-control {
    letter-spacing: normal;
    line-height: 1;
  }

  .mat-mdc-form-field-subscript-wrapper {
    height: 1em;
    .mat-mdc-form-field-hint-wrapper,
    .mat-mdc-form-field-error-wrapper {
      padding: 0;
      transform: translateY(-25%) !important;
      font-size: 1em;
      color: $timeline-border-color;
    }
  }

  .mat-mdc-form-field-icon-suffix {
    padding: 0 0 7px 4px;
    .mat-mdc-icon-button {
      border-radius: 50%;
      padding: 0;
      width: 1.5em;
      height: 1.5em;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1em;
      &:hover {
        background-color: $timeline-scrollbar-background-color;
        transform: scale(1);
      }
      .mat-mdc-button-persistent-ripple {
        display: none;
      }
      &[disabled] {
        opacity: 0.5;
      }
      .mat-ripple {
        display: none;
      }
      i.ti,
      mat-icon {
        width: 1em;
        height: 1em;
        font-size: 0.9em;
      }
      svg {
        width: 1em;
        height: 1em;
      }
    }
  }
}

mat-form-field[timeline][tl-no-underline],
mat-form-field.timeline[tl-no-underline],
mat-form-field[timeline].tl-no-underline,
mat-form-field.timeline.tl-no-underline {
  .mdc-line-ripple {
    display: none;
  }
}

mat-form-field[timeline][align-items-center],
mat-form-field.timeline[align-items-center],
mat-form-field[timeline].align-items-center,
mat-form-field.timeline.align-items-center {
  .mdc-text-field {
    &.mat-mdc-text-field-wrapper {
      align-items: center;
    }
  }
}

mat-form-field[timeline][tl-floating-label],
mat-form-field.timeline[tl-floating-label] {
  .mdc-text-field {
    &.mat-mdc-text-field-wrapper {
      .mat-mdc-form-field-infix {
        padding: 1.1em 0 0.4375em 0;
      }
      .mat-mdc-form-field-icon-suffix {
        min-height: 1.5em;
        .mat-mdc-icon-button {
          position: relative;
          top: 0.65em;
        }
      }
    }
  }
}

mat-form-field[timeline][tl-clear-button],
mat-form-field.timeline[tl-clear-button] {
  .mdc-text-field {
    &.mat-mdc-text-field-wrapper {
      .mat-mdc-form-field-infix {
        padding: 0;
      }
      .mat-mdc-form-field-icon-suffix {
        min-height: 1.5em;
        .mat-mdc-icon-button {
          position: relative;
          top: 0;
        }
      }
    }
  }
}

mat-form-field[timeline][tl-border],
mat-form-field.timeline[tl-border] {
    border: 1px solid;
    border-color: $timeline-color-black;
    border-radius: 24px;
    padding: 5px 15px;
  .mdc-text-field {
    &.mat-mdc-text-field-wrapper {
      .mat-mdc-form-field-infix {
        padding: 0.2em 0;
      }

      .mat-mdc-form-field-icon-suffix {
        padding: 0;
        font-size: 1.2em;
        margin-left: 5px;
      }

      .mat-mdc-form-field-flex {
        align-items: center;
      }
    }
  }
  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }
  .mdc-line-ripple {
    display: none;
  }
}

mat-form-field[timeline][tl-border=gray],
mat-form-field.timeline[tl-border=gray] {
  border-color: $timeline-border-color;
}

mat-form-field[timeline][tl-border=orange],
mat-form-field.timeline[tl-border=orange] {
  border-color: $timeline-border-color-orange;
}

mat-form-field[timeline][tl-background=orange],
mat-form-field.timeline[tl-background=orange] {
  background-color: $timeline-border-color-orange;
}

mat-form-field[timeline=orange] {
  &.mat-focused {
    border: 1px solid $timeline-border-color-orange;
    background: $timeline-border-color-orange-light;
  }
  &:hover {
    border: 1px solid $timeline-border-color-orange;
    background: $timeline-border-color-orange-light;
  }
}


mat-form-field[timeline][tl-compact-height],
mat-form-field.timeline[tl-compact-height] {
  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }
}

mat-form-field[timeline][tl-compact],
mat-form-field.timeline[tl-compact] {
  .mdc-text-field {
    &.mat-mdc-text-field-wrapper {
      .mat-mdc-form-field-infix {
        padding-bottom: 0;
        padding-top: 0.4375em;
      }
    }
  }
  .mat-mdc-form-field-icon-suffix {
    font-size: 0.7em;
  }
  .mdc-line-ripple {
    font-size: 0.7em;
  }
  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }
}

mat-form-field[timeline][tl-compact=thin],
mat-form-field.timeline[tl-compact=thin] {
  .mdc-text-field {
    &.mat-mdc-text-field-wrapper {
      .mat-mdc-form-field-infix {
        padding-top: 0;
      }
    }
  }
}

mat-form-field[timeline][underline-color-inherit],
mat-form-field.timeline[underline-color-inherit] {
  .mdc-text-field {
    &.mat-mdc-text-field-wrapper {
      .mdc-line-ripple::after {
        border-bottom-color: inherit;
      }
    }
  }
}

mat-form-field[timeline][underline=align-bottom],
mat-form-field.timeline[underline=align-bottom] {
  .mdc-text-field {
    &.mat-mdc-text-field-wrapper {
      .mdc-line-ripple {
        &::before,
        &::after {
          bottom: 0;
        }
      }
      .mat-mdc-form-field-infix .mat-mdc-input-element {
        margin-bottom: 4px;
      }
    }
  }
}

mat-form-field[timeline][input-height=h100],
mat-form-field.timeline[input-height=h100] {
  .mdc-text-field--no-label:not(.mdc-text-field--textarea) .mat-mdc-form-field-input-control.mdc-text-field__input, .mat-mdc-text-field-wrapper .mat-mdc-form-field-input-control {
    height: 100%;
  }
}

mat-form-field[status-switch] {
  max-width: 121px;
  position: relative;
  top: 40px;
  font-size: 15px;
  .mdc-text-field {
    &.mat-mdc-text-field-wrapper {
      .mat-mdc-form-field-infix {
        padding-bottom: 0;
      }
    }
  }

  .mat-mdc-select-trigger {
    .mat-mdc-select-arrow-wrapper {
      // transform: translate(0px, -2px);
      --mat-mdc-select-arrow-wrapper-transformt: translate(0px, -2px);
    }

    .mat-mdc-select-value-text {
      padding: 3px 10px 2px 10px;
      border-radius: 20px;
      margin-bottom: 3px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 5px;
    }
  }

  &.open {
    .mat-mdc-select-trigger {
      .mat-mdc-select-value-text {
        background-color: $timeline-color-open;
      }
    }
  }

  &.planned {
    .mat-mdc-select-trigger {
      .mat-mdc-select-value-text {
        background-color: $timeline-color-planned;
      }
    }
  }

  &.draft {
    .mat-mdc-select-trigger {
      .mat-mdc-select-value-text {
        background-color: $timeline-color-draft;
      }
    }
  }
}

mat-form-field.with-translate-button {
  .mat-mdc-form-field-icon-suffix {
    padding: 5px 0 0 4px;
    .mat-mdc-icon-button {
      font-size: 1.5em;
      top: 0 !important;
      app-loading-progress {
        width: 1em;
        height: 1em;
      }
    }
  }
  &.translating {
    .mat-mdc-form-field-icon-suffix {
      .mat-mdc-icon-button {
        cursor: default;
        pointer-events: none;
      }
    }
  }
  .mdc-line-ripple {
    &:before,
    &:after {
      width: calc(100% - 2.2em);
    }
  }
}

mat-form-field[timeline][overflow-y=auto],
mat-form-field.timeline[overflow-y=auto] {
  .mdc-text-field {
    &.mat-mdc-text-field-wrapper {
      overflow-y: auto;
      &::-webkit-scrollbar-track {
        background-color: $timeline-color-white;
      }
      &::-webkit-scrollbar-track-piece {
        background-color: $timeline-content-editor-track-piece;
      }
      &::-webkit-scrollbar-thumb {
        background-color: $timeline-content-editor-scrollbar-thumb;
      }
      &::-webkit-scrollbar {
        background-color: $timeline-color-white;
        width: $scrollbar-width;
      }
      // FireFox scrollbar style
      @supports (not selector(::-webkit-scrollbar)) {
        scrollbar-width: thin;
      }
    }
  }
}

mat-form-field[timeline][tl-mat-suffix-display="flex"],
mat-form-field.timeline[tl-mat-suffix-display="flex"] {
  .mat-mdc-form-field-icon-suffix {
    display: flex;
    align-items: center;
    gap: 4px;
  }
}

/* mat-form-field */
/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
mat-form-field.small.mat-form-field-appearance-outline div.mat-form-field-infix {
  padding: 0.2em 0 0.5em 0;
}

mat-form-field.small input.mat-mdc-input-element {
  font-size: 14px;
}

mat-form-field[color=white] input.mat-mdc-input-element {
  color: white;
}
/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
mat-form-field[color=white] .mat-form-field-label {
  color: $timeline-color-white-07;
}
/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
mat-form-field[color=white] .mat-form-field-underline {
  background-color: $timeline-color-white;
}
/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
mat-form-field[marginBottom=n125em] .mat-form-field-wrapper {
  margin-bottom: -1.25em;
}
/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
mat-form-field[label=fs12px] .mat-form-field-label {
  font-size: 12px;
}

/*
mat-form-field[timeline][text-align-center] {
  !* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. *!
  .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix {
    text-align: center;
  }
}
*/

/*
mat-form-field[timeline][no-padding] {
  padding: 0;
  !* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. *!
  .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix {
    padding-bottom: 0;
  }
}
*/

mat-form-field[grid-cell] {
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  .mat-form-field-wrapper {
    padding-bottom: 0;
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    .mat-form-field-flex {
      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
      .mat-form-field-infix {
        border: 0;
      }
    }
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    .mat-form-field-underline {
      display: none;
    }
  }

}
