mat-chip-option[timeline=avatar] {
  &.mat-mdc-standard-chip.mdc-evolution-chip--with-primary-graphic.mdc-evolution-chip--with-trailing-action .mdc-evolution-chip__graphic {
    display: none;
  }
  &.mat-mdc-chip.mdc-evolution-chip--with-trailing-action .mat-mdc-chip-action-label {
    pointer-events: none;
    padding: 0;
  }
}

mat-chip-listbox[timeline] {
  .mdc-evolution-chip-set__chips {
    gap: var(--mdc-evolution-chip-set__chips_gap, 0);
    display: var(--mdc-evolution-chip-set__chips_display, flex);
    grid-template-columns: var(--mdc-evolution-chip-set__chips_grid-template-columns, unset);
  }
}

mat-chip-option[timeline=icon-text] {
  &.mat-mdc-standard-chip.mdc-evolution-chip--with-primary-graphic.mdc-evolution-chip--with-trailing-action .mdc-evolution-chip__graphic {
    display: none;
  }
  &.mat-mdc-chip.mdc-evolution-chip--with-trailing-action .mat-mdc-chip-action-label {
    display: flex;
    align-items: center;
    gap: 5px;
    padding-left: 10px;
  }
}

mat-chip-grid[timeline] {
  .mdc-evolution-chip-set__chips {
    margin-left: 0;
    margin-right: 0;
  }
}

mat-chip-row[timeline] {
  .mdc-evolution-chip__text-label {
    display: flex;
    align-items: center;
  }
}
