// Alignment
[class*="tl-"] h1,
[class*="tl-"] h2,
[class*="tl-"] h3,
[class*="tl-"] h4,
[class*="tl-"] h5,
[class*="tl-"] h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

.tl-text-left {
  text-align: left !important;
}
.tl-text-right {
  text-align: right !important;
}
.tl-text-center {
  text-align: center !important;
}
.tl-text-justify {
  text-align: justify !important;
}
.tl-text-nowrap {
  white-space: nowrap !important;
}

// Transformation
.tl-text-lowercase {
  text-transform: lowercase;
}
.tl-text-uppercase {
  text-transform: uppercase;
}
.tl-text-capitalize {
  text-transform: capitalize;
}
.tl-text-muted {
  color: $text-muted !important;
}
