mat-dialog-container.mat-mdc-dialog-container {
  --mdc-dialog-subhead-tracking: normal;
  --mdc-dialog-supporting-text-tracking: normal;
  --mat-dialog-transition-duration: 0 !important;
}

.timeline .mat-mdc-dialog-container {
  font-family: $timeline-font;
  .mdc-dialog__title {
    font-family: $timeline-font;
    padding: var(--mdc-dialog__title-padding-top, 0) var(--mdc-dialog__title-padding-right, 0) var(--mdc-dialog__title-padding-bottom, 0) var(--mdc-dialog__title-padding-left, 0);
    &:before {
      content: none;
    }
  }
  .mdc-dialog__surface {
    padding: var(--mdc-dialog__surface-padding-top, 24px) var(--mdc-dialog__surface-padding-right, 24px) var(--mdc-dialog__surface-padding-bottom, 10px) var(--mdc-dialog__surface-padding-left, 24px);
    display: var(--mdc-dialog__surface-display, table);
    box-sizing: border-box;
    overflow: var(--mdc-dialog__surface-overflow, auto);
    outline: 0;
    width: 100%;
    height: 100%;
    min-height: inherit;
    max-height: inherit;
    border-radius: var(--mdc-dialog__surface-border-radius, 16px);
    &:before {
      content: none;
    }
  }
  .mdc-dialog__content {
    font-family: $timeline-font;
    line-height: initial;
  }

  .mat-mdc-dialog-actions {
    justify-content: flex-end;
    padding: var(--mdc-dialog__actions-padding-top, 0)var(--mdc-dialog__actions-padding-right, 0) var(--mdc-dialog__actions-padding-bottom, 0) var(--mdc-dialog__actions-padding-left, 0);
  }
}

.my-notes .mat-mdc-dialog-container {
  font-family: $timeline-font;
  .mdc-dialog__title {
    font-family: $timeline-font;
    padding: var(--mdc-dialog__title-padding-top, 0) var(--mdc-dialog__title-padding-right, 0) var(--mdc-dialog__title-padding-bottom, 0) var(--mdc-dialog__title-padding-left, 0);
    &:before {
      content: none;
    }
  }
  .mdc-dialog__surface {
    padding: 32px;
    display: var(--mdc-dialog__surface-display, table);
    box-sizing: border-box;
    overflow: var(--mdc-dialog__surface-overflow, auto);
    outline: 0;
    width: 100%;
    height: 100%;
    min-height: inherit;
    max-height: inherit;
    border-radius: 20px;
    &:before {
      content: none;
    }
  }
  .mdc-dialog__content {
    font-family: $timeline-font;
    line-height: initial;
  }

  .mat-mdc-dialog-actions {
    justify-content: flex-end;
    padding: var(--mdc-dialog__actions-padding-top, 0)var(--mdc-dialog__actions-padding-right, 0) var(--mdc-dialog__actions-padding-bottom, 0) var(--mdc-dialog__actions-padding-left, 0);
  }
}


.add-group-dialog .mat-mdc-dialog-container {
  padding: 0;
  transform: none;
}

.manage-time-select-time-dialog .mat-mdc-dialog-container {
  .mdc-dialog__surface {
    padding: 0 16px;
    overflow: hidden;
  }
}

.manage-time-dialog .mat-mdc-dialog-container {
  .mdc-dialog__surface {
    overflow: hidden;
  }
}

.content-container-editor-dialog .mat-mdc-dialog-container {
  --mdc-dialog__surface-padding-top: 0;
  --mdc-dialog__surface-padding-right: 0;
  --mdc-dialog__surface-padding-bottom: 0;
  --mdc-dialog__surface-padding-left: 0;
  --mdc-dialog__surface-overflow: hidden;
  --mdc-dialog__surface-border-radius: 0;
  .mdc-dialog__surface {
    background: none;
    box-shadow: none;
  }
}

.report-bug-get-support-dialog-panel {
  z-index: 1200 !important;
}

.timeline.create-event-dialog .mat-mdc-dialog-container {
  .mdc-dialog__surface {
    padding: 45px 0 60px 45px;
    background-color: transparent;
    box-shadow: none;
  }
}

.timeline.tree-reference {
  --mdc-dialog__surface-padding-top: 15px;
  --mdc-dialog__surface-padding-left: 15px;
  --mdc-dialog__surface-padding-right: 15px;
}

.timeline.questions-participants-detail {
  --mdc-dialog__surface-padding-top: 0;
  --mdc-dialog__surface-padding-right: 0;
  --mdc-dialog__surface-padding-bottom: 0;
  --mdc-dialog__surface-padding-left: 0;
  --mdc-dialog__surface-overflow: hidden;
}

.timeline.folder-properties {
  --mdc-dialog__surface-padding-top: 15px;
}

.timeline.send-message {
  --mdc-dialog__surface-padding-top: 16px;
  --mdc-dialog__surface-padding-right: 16px;
  --mdc-dialog__surface-padding-bottom: 0;
  --mdc-dialog__surface-padding-left: 16px;
  .mdc-dialog__surface {
    background-color: $timeline-background-color-very-light;
  }
}

.transparent-backdrop {
  background-color: transparent;
}
