mat-expansion-panel {
  .mat-expansion-panel-content {
    font-family: $timeline-font;
    letter-spacing: normal;
  }
  .mat-expansion-panel-header {
    font-family: $timeline-font;
    letter-spacing: normal;
    font-size: 15px;
  }
}

mat-expansion-panel[timeline-tree-panel] {
  .mat-expansion-panel-header {
    &:not(.section-display-only) {
      .mat-expansion-indicator {
        display: none;
      }
    }
    &.section-of-type-end {
      .mat-expansion-indicator {
        display: none;
      }
    }
    &.no-children {
      .mat-expansion-indicator {
        display: none;
      }
    }
    .mat-expansion-indicator {
      margin: 0 !important;
      position: absolute;
      left: 33px;
      &:after {
        color: $timeline-border-color-orange;
      }
    }
    &:not(.mat-expanded) {
      .mat-expansion-indicator {
        left: 32px;
        &:after {
          color: $timeline-background-color-dark;
          border-width: 0 3px 3px 0;
          border-radius: 1px;
        }
      }
    }
  }
  &.hide-expansion-indicator {
    .mat-expansion-indicator {
      display: none !important;
    }
  }
}

mat-expansion-panel[timeline-panel] {
  border-radius: 24px;

  .mat-expansion-indicator::after {
    border-style: unset;
    border-width: unset;
    content: "";
    display: inline-block;
    padding: unset;
    transform: unset;
    vertical-align: middle;
    color: $timeline-border-color-orange;
    border-left: 5px solid rgba(0,0,0,0);
    border-right: 5px solid rgba(0,0,0,0);
    border-top: 5px solid;
  }

  &.expansion-panel-body-flex-column {
    .mat-expansion-panel-body {
      display: flex;
      flex-direction: column;
    }
    &.gap-15 {
      .mat-expansion-panel-body {
        gap: 15px;
      }
    }
  }
  &[timeline-panel-body-padding] {
    .mat-expansion-panel-body {
      padding: var(--timeline-panel-body-padding, 0 24px 16px);
    }
  }
  &[left-down-chevron-indicator] {
    .mat-expansion-indicator{
      transform: rotate(90deg) !important;
      &:after {
        font-family: "tabler-icons";
        content: "\ea5f";
        border: none;
      }
    }
    &.mat-expanded {
      .mat-expansion-indicator {
         transform: rotate(0deg) !important;
      }
    }
  }
  &[left-down-chevron-indicator=white] {
    .mat-expansion-indicator{
      &:after {
        color: $timeline-color-white;
      }
    }
  }
}
