@mixin flexbox(
  $fb-display: flex,
  $fb-direction: row,
  $fb-wrap: nowrap,
  $fb-justify-content: normal,
  $fb-align-content: normal,
  $fb-justify-items:  normal,
  $fb-align-items: normal
) {
  //display: flex or inline-flex */
  display: -webkit-$fb-display;
  display: -moz-$fb-display;
  display: -ms-$fb-display;
  display: $fb-display;

  //flex-direction: row | row-reverse | column | column-reverse;
  -webkit-flex-direction: $fb-direction;
  -moz-flex-direction: $fb-direction;
  -ms-flex-direction: $fb-direction;
  -o-flex-direction: $fb-direction;
  flex-direction: $fb-direction;

  //flex-wrap: nowrap | wrap | wrap-reverse;
  -webkit-flex-wrap: $fb-wrap;
  -moz-flex-wrap: $fb-wrap;
  -ms-flex-wrap: $fb-wrap;
  -o-flex-wrap: $fb-wrap;
  flex-wrap: $fb-wrap;

  //justify-content: flex-start | flex-end | center | space-between | space-around;
  -webkit-justify-content: $fb-justify-content;
  -moz-justify-content: $fb-justify-content;
  -ms-justify-content: $fb-justify-content;
  -o-justify-content: $fb-justify-content;
  justify-content: $fb-justify-content;

  //align-content: flex-start | flex-end | center | space-between | space-around | stretch;
  -webkit-align-content: $fb-align-content;
  -moz-align-content: $fb-align-content;
  -ms-align-content: $fb-align-content;
  -o-align-content: $fb-align-content;
  align-content: $fb-align-content;

  //justify-items: flex-start | flex-end | center | space-between | space-around;
  -webkit-justify-items: $fb-justify-items;
  -moz-justify-items: $fb-justify-items;
  -ms-justify-items: $fb-justify-items;
  -o-justify-items: $fb-justify-items;
  justify-items: $fb-justify-items;

  //align-items: flex-start | flex-end | center | baseline | stretch;
  -webkit-align-items: $fb-align-items;
  -moz-align-items: $fb-align-items;
  -ms-align-items: $fb-align-items;
  -o-align-items: $fb-align-items;
  align-items: $fb-align-items;
}

@mixin display-flex($fb-display: flex) {
  display: -webkit-$fb-display;
  display: -moz-$fb-display;
  display: -ms-$fb-display;
  display: $fb-display;
}

@mixin flexDirection($fb-direction: row) {
  -webkit-flex-direction: $fb-direction;
  -moz-flex-direction: $fb-direction;
  -ms-flex-direction: $fb-direction;
  -o-flex-direction: $fb-direction;
  flex-direction: $fb-direction;
}

@mixin flexWrap($fb-wrap: nowrap) {
  -webkit-flex-wrap: $fb-wrap;
  -moz-flex-wrap: $fb-wrap;
  -ms-flex-wrap: $fb-wrap;
  -o-flex-wrap: $fb-wrap;
  flex-wrap: $fb-wrap;
}

@mixin flexJustifyContent($fb-justify-content: normal) {
  -webkit-justify-content: $fb-justify-content;
  -moz-justify-content: $fb-justify-content;
  -ms-justify-content: $fb-justify-content;
  -o-justify-content: $fb-justify-content;
  justify-content: $fb-justify-content;
}

@mixin flexAlignContent($fb-align-content: normal) {
  -webkit-align-content: $fb-align-content;
  -moz-align-content: $fb-align-content;
  -ms-align-content: $fb-align-content;
  -o-align-content: $fb-align-content;
  align-content: $fb-align-content;
}

@mixin flexJustifyItems($fb-justify-items: normal) {
  -webkit-justify-items: $fb-justify-items;
  -moz-justify-items: $fb-justify-items;
  -ms-justify-items: $fb-justify-items;
  -o-justify-items: $fb-justify-items;
  justify-items: $fb-justify-items;
}

@mixin flexAlignItems($fb-align-items: normal) {
  -webkit-align-items: $fb-align-items;
  -moz-align-items: $fb-align-items;
  -ms-align-items: $fb-align-items;
  -o-align-items: $fb-align-items;
  align-items: $fb-align-items;
}

@mixin flexGrow($fb-flex-grow: 1) {
  -webkit-flex-grow: $fb-flex-grow;
  -moz-flex-grow: $fb-flex-grow;
  -ms-flex-grow: $fb-flex-grow;
  -o-flex-grow: $fb-flex-grow;
  flex-grow: $fb-flex-grow;
}


@mixin order($order) {
  -webkit-order: $order;
  -moz-order: $order;
  -ms-order: $order;
  -o-order: $order;
  order: $order;
}
