@import 'timeline';

.mat-button-toggle-group-appearance-standard {
  font-family: $timeline-font;
  --mat-standard-button-toggle-divider-color: transparent;
  --mat-standard-button-toggle-label-text-size: 14px;
  .mat-button-toggle-button {
    text-align: var(--mat-standard-button-toggle-label-text-align, inherit);
    min-height: var(--mat-standard-button-min-height, inherit);
    .mat-button-toggle-label-content {
      font-family: $timeline-font;
      .mat-pseudo-checkbox {
        display: none;
      }
    }
  }
}

.mat-button-toggle-group-appearance-standard[color=black-white] {
  height: 40px;
  border-radius: 16px;
  align-items: center;
  padding: 0 5px 0 5px;
  box-sizing: border-box;
  border: none;
  gap: 5px;

  .mat-button-toggle {
    height: 32px;
    border-radius: 12px;
    background-color: transparent;
    border: none;
  }

  .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
    color: white;

    .mat-button-toggle-button {
      background-color: black;
      border-radius: 12px;
    }
  }

  .mat-button-toggle-button {
    .mat-button-toggle-label-content {
      line-height: 32px;
    }
  }

  span.mat-ripple.mat-button-toggle-ripple {
    border-radius: 12px;
  }
}

.mat-button-toggle-group-appearance-standard[timeline] {
  &:not([class*=mat-elevation-z]) {
    border-radius: 16px;
    box-shadow: $toolbar-container-box-shadow;
  }
}

@media (max-width: 767px) {
  mat-button-toggle-group[dashboardToolbar] {
    justify-content: space-between;
    z-index: 999;
    position: fixed;
    bottom: 16px;
    left: 50%;
    transform: translateX(-50%);
    box-shadow: 2px 4px 10px 0 #00000033 !important;
    width: 310px;

    .mat-button-toggle {
      button {
        .mat-button-toggle-label-content {
          padding: 0 7px;
        }
        .event-title {
          display: none;
        }
      }
    }

    .mat-button-toggle-checked {
      button {
        width: 148px;
        .event-title {
          display: inline-block;
        }
      }
    }
  }
}
