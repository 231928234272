.clearfix {
  @include clearfix();
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@include MQ(XL) {
  .hidden-xl-up {
    display: none !important;
  }
}

.visible-print-block {
  display: none !important;
}

@media print {
  .visible-print-block {
    display: block !important;
  }
}

.visible-print-inline {
  display: none !important;
}

@media print {
  .visible-print-inline {
    display: inline !important;
  }
}

.visible-print-inline-block {
  display: none !important;
}

@media print {
  .visible-print-inline-block {
    display: inline-block !important;
  }
}

@media print {
  .hidden-print {
    display: none !important;
  }
}

.tl-container {
  @include container-fixed;

  @include MQ(SM) {
    max-width: 540px;
  }
  @include MQ(MD) {
    max-width: 720px;
  }
  @include MQ(LG) {
    max-width: 960px;
  }
  @include MQ(XL) {
    max-width: 1140px;
  }
}

.tl-container-fluid {
  @include container-fixed;
}

.tl-row {
  display: -webkit-box !important;
  display: -webkit-flex !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-flex-wrap: wrap !important;
  -ms-flex-wrap: wrap !important;
  -moz-flex-wrap: wrap !important;
  -o-flex-wrap: wrap !important;
  flex-wrap: wrap !important;
  margin-right: -15px !important;
  margin-left: -15px !important;
}

.no-gutters {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.no-gutters > .tl-col,
.no-gutters > [class*="tl-col-"] {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.tl-col-1,
.tl-col-2,
.tl-col-3,
.tl-col-4,
.tl-col-5,
.tl-col-6,
.tl-col-7,
.tl-col-8,
.tl-col-9,
.tl-col-10,
.tl-col-11,
.tl-col-12,
.tl-col,
.tl-col-auto,
.tl-col-sm-1,
.tl-col-sm-2,
.tl-col-sm-3,
.tl-col-sm-4,
.tl-col-sm-5,
.tl-col-sm-6,
.tl-col-sm-7,
.tl-col-sm-8,
.tl-col-sm-9,
.tl-col-sm-10,
.tl-col-sm-11,
.tl-col-sm-12,
.tl-col-sm,
.tl-col-sm-auto,
.tl-col-md-1,
.tl-col-md-2,
.tl-col-md-3,
.tl-col-md-4,
.tl-col-md-5,
.tl-col-md-6,
.tl-col-md-7,
.tl-col-md-8,
.tl-col-md-9,
.tl-col-md-10,
.tl-col-md-11,
.tl-col-md-12,
.tl-col-md,
.tl-col-md-auto,
.tl-col-lg-1,
.tl-col-lg-2,
.tl-col-lg-3,
.tl-col-lg-4,
.tl-col-lg-5,
.tl-col-lg-6,
.tl-col-lg-7,
.tl-col-lg-8,
.tl-col-lg-9,
.tl-col-lg-10,
.tl-col-lg-11,
.tl-col-lg-12,
.tl-col-lg,
.tl-col-lg-auto,
.tl-col-xl-1,
.tl-col-xl-2,
.tl-col-xl-3,
.tl-col-xl-4,
.tl-col-xl-5,
.tl-col-xl-6,
.tl-col-xl-7,
.tl-col-xl-8,
.tl-col-xl-9,
.tl-col-xl-10,
.tl-col-xl-11,
.tl-col-xl-12,
.tl-col-xl,
.tl-col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.tl-col {
  flex-basis: 0;
  -webkit-flex-basis: 0;
  -ms-flex-basis: 0;
  -moz-flex-basis: 0;
  -o-flex-basis: 0;
  -ms-flex-preferred-size: 0;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}

.tl-col-auto {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  -moz-flex: 0 0 auto;
  -o-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.tl-col-1 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 8.3333333333%;
  -ms-flex: 0 0 8.3333333333%;
  -moz-flex: 0 0 8.3333333333%;
  -o-flex: 0 0 8.3333333333%;
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

.tl-col-2 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 16.6666666667%;
  -ms-flex: 0 0 16.6666666667%;
  -moz-flex: 0 0 16.6666666667%;
  -o-flex: 0 0 16.6666666667%;
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.tl-col-3 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 25%;
  -ms-flex: 0 0 25%;
  -moz-flex: 0 0 25%;
  -o-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}

.tl-col-4 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 33.3333333333%;
  -ms-flex: 0 0 33.3333333333%;
  -moz-flex: 0 0 33.3333333333%;
  -o-flex: 0 0 33.3333333333%;
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.tl-col-5 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 41.6666666667%;
  -ms-flex: 0 0 41.6666666667%;
  -moz-flex: 0 0 41.6666666667%;
  -o-flex: 0 0 41.6666666667%;
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}

.tl-col-6 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 50%;
  -ms-flex: 0 0 50%;
  -moz-flex: 0 0 50%;
  -o-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.tl-col-7 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 58.3333333333%;
  -ms-flex: 0 0 58.3333333333%;
  -moz-flex: 0 0 58.3333333333%;
  -o-flex: 0 0 58.3333333333%;
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

.tl-col-8 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 66.6666666667%;
  -ms-flex: 0 0 66.6666666667%;
  -moz-flex: 0 0 66.6666666667%;
  -o-flex: 0 0 66.6666666667%;
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

.tl-col-9 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 75%;
  -ms-flex: 0 0 75%;
  -moz-flex: 0 0 75%;
  -o-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%;
}

.tl-col-10 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 83.3333333333%;
  -ms-flex: 0 0 83.3333333333%;
  -moz-flex: 0 0 83.3333333333%;
  -o-flex: 0 0 83.3333333333%;
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

.tl-col-11 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 91.6666666667%;
  -ms-flex: 0 0 91.6666666667%;
  -moz-flex: 0 0 91.6666666667%;
  -o-flex: 0 0 91.6666666667%;
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}

.tl-col-12 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 100%;
  -ms-flex: 0 0 100%;
  -moz-flex: 0 0 100%;
  -o-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

@include MQ(SM) {
  .tl-col-sm {
    -webkit-flex-basis: 0;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .tl-col-sm-auto {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    -moz-flex: 0 0 auto;
    -o-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .tl-col-sm-1 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 8.3333333333%;
    -ms-flex: 0 0 8.3333333333%;
    -moz-flex: 0 0 8.3333333333%;
    -o-flex: 0 0 8.3333333333%;
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .tl-col-sm-2 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 16.6666666667%;
    -ms-flex: 0 0 16.6666666667%;
    -moz-flex: 0 0 16.6666666667%;
    -o-flex: 0 0 16.6666666667%;
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .tl-col-sm-3 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 25%;
    -ms-flex: 0 0 25%;
    -moz-flex: 0 0 25%;
    -o-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .tl-col-sm-4 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33.3333333333%;
    -ms-flex: 0 0 33.3333333333%;
    -moz-flex: 0 0 33.3333333333%;
    -o-flex: 0 0 33.3333333333%;
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .tl-col-sm-5 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 41.6666666667%;
    -ms-flex: 0 0 41.6666666667%;
    -moz-flex: 0 0 41.6666666667%;
    -o-flex: 0 0 41.6666666667%;
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .tl-col-sm-6 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
    -ms-flex: 0 0 50%;
    -moz-flex: 0 0 50%;
    -o-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .tl-col-sm-7 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 58.3333333333%;
    -ms-flex: 0 0 58.3333333333%;
    -moz-flex: 0 0 58.3333333333%;
    -o-flex: 0 0 58.3333333333%;
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .tl-col-sm-8 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 66.6666666667%;
    -ms-flex: 0 0 66.6666666667%;
    -moz-flex: 0 0 66.6666666667%;
    -o-flex: 0 0 66.6666666667%;
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .tl-col-sm-9 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 75%;
    -ms-flex: 0 0 75%;
    -moz-flex: 0 0 75%;
    -o-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .tl-col-sm-10 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 83.3333333333%;
    -ms-flex: 0 0 83.3333333333%;
    -moz-flex: 0 0 83.3333333333%;
    -o-flex: 0 0 83.3333333333%;
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .tl-col-sm-11 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 91.6666666667%;
    -ms-flex: 0 0 91.6666666667%;
    -moz-flex: 0 0 91.6666666667%;
    -o-flex: 0 0 91.6666666667%;
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .tl-col-sm-12 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    -moz-flex: 0 0 100%;
    -o-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@include MQ(MD) {
  .tl-col-md {
    -webkit-flex-basis: 0;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .tl-col-md-auto {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    -moz-flex: 0 0 auto;
    -o-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .tl-col-md-1 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 8.3333333333%;
    -ms-flex: 0 0 8.3333333333%;
    -moz-flex: 0 0 8.3333333333%;
    -o-flex: 0 0 8.3333333333%;
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .tl-col-md-2 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 16.6666666667%;
    -ms-flex: 0 0 16.6666666667%;
    -moz-flex: 0 0 16.6666666667%;
    -o-flex: 0 0 16.6666666667%;
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .tl-col-md-3 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 25%;
    -ms-flex: 0 0 25%;
    -moz-flex: 0 0 25%;
    -o-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .tl-col-md-4 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33.3333333333%;
    -ms-flex: 0 0 33.3333333333%;
    -moz-flex: 0 0 33.3333333333%;
    -o-flex: 0 0 33.3333333333%;
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .tl-col-md-5 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 41.6666666667%;
    -ms-flex: 0 0 41.6666666667%;
    -moz-flex: 0 0 41.6666666667%;
    -o-flex: 0 0 41.6666666667%;
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .tl-col-md-6 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
    -ms-flex: 0 0 50%;
    -moz-flex: 0 0 50%;
    -o-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .tl-col-md-7 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 58.3333333333%;
    -ms-flex: 0 0 58.3333333333%;
    -moz-flex: 0 0 58.3333333333%;
    -o-flex: 0 0 58.3333333333%;
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .tl-col-md-8 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 66.6666666667%;
    -ms-flex: 0 0 66.6666666667%;
    -moz-flex: 0 0 66.6666666667%;
    -o-flex: 0 0 66.6666666667%;
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .tl-col-md-9 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 75%;
    -ms-flex: 0 0 75%;
    -moz-flex: 0 0 75%;
    -o-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .tl-col-md-10 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 83.3333333333%;
    -ms-flex: 0 0 83.3333333333%;
    -moz-flex: 0 0 83.3333333333%;
    -o-flex: 0 0 83.3333333333%;
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .tl-col-md-11 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 91.6666666667%;
    -ms-flex: 0 0 91.6666666667%;
    -moz-flex: 0 0 91.6666666667%;
    -o-flex: 0 0 91.6666666667%;
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .tl-col-md-12 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    -moz-flex: 0 0 100%;
    -o-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@include MQ(LG) {
  .tl-col-lg {
    -webkit-flex-basis: 0;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .tl-col-lg-auto {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    -moz-flex: 0 0 auto;
    -o-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .tl-col-lg-1 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 8.3333333333%;
    -ms-flex: 0 0 8.3333333333%;
    -moz-flex: 0 0 8.3333333333%;
    -o-flex: 0 0 8.3333333333%;
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .tl-col-lg-2 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 16.6666666667%;
    -ms-flex: 0 0 16.6666666667%;
    -moz-flex: 0 0 16.6666666667%;
    -o-flex: 0 0 16.6666666667%;
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .tl-col-lg-3 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 25%;
    -ms-flex: 0 0 25%;
    -moz-flex: 0 0 25%;
    -o-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .tl-col-lg-4 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33.3333333333%;
    -ms-flex: 0 0 33.3333333333%;
    -moz-flex: 0 0 33.3333333333%;
    -o-flex: 0 0 33.3333333333%;
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .tl-col-lg-5 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 41.6666666667%;
    -ms-flex: 0 0 41.6666666667%;
    -moz-flex: 0 0 41.6666666667%;
    -o-flex: 0 0 41.6666666667%;
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .tl-col-lg-6 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
    -ms-flex: 0 0 50%;
    -moz-flex: 0 0 50%;
    -o-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .tl-col-lg-7 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 58.3333333333%;
    -ms-flex: 0 0 58.3333333333%;
    -moz-flex: 0 0 58.3333333333%;
    -o-flex: 0 0 58.3333333333%;
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .tl-col-lg-8 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 66.6666666667%;
    -ms-flex: 0 0 66.6666666667%;
    -moz-flex: 0 0 66.6666666667%;
    -o-flex: 0 0 66.6666666667%;
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .tl-col-lg-9 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 75%;
    -ms-flex: 0 0 75%;
    -moz-flex: 0 0 75%;
    -o-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .tl-col-lg-10 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 83.3333333333%;
    -ms-flex: 0 0 83.3333333333%;
    -moz-flex: 0 0 83.3333333333%;
    -o-flex: 0 0 83.3333333333%;
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .tl-col-lg-11 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 91.6666666667%;
    -ms-flex: 0 0 91.6666666667%;
    -moz-flex: 0 0 91.6666666667%;
    -o-flex: 0 0 91.6666666667%;
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .tl-col-lg-12 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    -moz-flex: 0 0 100%;
    -o-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@include MQ(XL) {
  .tl-col-xl {
    -webkit-flex-basis: 0;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .tl-col-xl-auto {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    -moz-flex: 0 0 auto;
    -o-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .tl-col-xl-1 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 8.3333333333%;
    -ms-flex: 0 0 8.3333333333%;
    -moz-flex: 0 0 8.3333333333%;
    -o-flex: 0 0 8.3333333333%;
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .tl-col-xl-2 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 16.6666666667%;
    -ms-flex: 0 0 16.6666666667%;
    -moz-flex: 0 0 16.6666666667%;
    -o-flex: 0 0 16.6666666667%;
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .tl-col-xl-3 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 25%;
    -ms-flex: 0 0 25%;
    -moz-flex: 0 0 25%;
    -o-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .tl-col-xl-4 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33.3333333333%;
    -ms-flex: 0 0 33.3333333333%;
    -moz-flex: 0 0 33.3333333333%;
    -o-flex: 0 0 33.3333333333%;
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .tl-col-xl-5 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 41.6666666667%;
    -ms-flex: 0 0 41.6666666667%;
    -moz-flex: 0 0 41.6666666667%;
    -o-flex: 0 0 41.6666666667%;
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .tl-col-xl-6 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
    -ms-flex: 0 0 50%;
    -moz-flex: 0 0 50%;
    -o-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .tl-col-xl-7 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 58.3333333333%;
    -ms-flex: 0 0 58.3333333333%;
    -moz-flex: 0 0 58.3333333333%;
    -o-flex: 0 0 58.3333333333%;
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .tl-col-xl-8 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 66.6666666667%;
    -ms-flex: 0 0 66.6666666667%;
    -moz-flex: 0 0 66.6666666667%;
    -o-flex: 0 0 66.6666666667%;
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .tl-col-xl-9 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 75%;
    -ms-flex: 0 0 75%;
    -moz-flex: 0 0 75%;
    -o-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .tl-col-xl-10 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 83.3333333333%;
    -ms-flex: 0 0 83.3333333333%;
    -moz-flex: 0 0 83.3333333333%;
    -o-flex: 0 0 83.3333333333%;
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .tl-col-xl-11 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 91.6666666667%;
    -ms-flex: 0 0 91.6666666667%;
    -moz-flex: 0 0 91.6666666667%;
    -o-flex: 0 0 91.6666666667%;
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .tl-col-xl-12 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    -moz-flex: 0 0 100%;
    -o-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.img-fluid {
  max-width: 100%;
  height: auto;
}
