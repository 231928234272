@mixin col-x-spacing {
  @for $i from 0 through $columns {
    .tl-m-#{$i} {
      @include m($i);
    }
    .tl-mt-#{$i} {
      @include mt($i);
    }
    .tl-mb-#{$i} {
      @include mb($i);
    }
    .tl-ml-#{$i} {
      @include ml($i);
    }
    .tl-mr-#{$i} {
      @include mr($i);
    }
    .tl-p-#{$i} {
      @include p($i);
    }
    .tl-pt-#{$i} {
      @include pt($i);
    }
    .tl-pb-#{$i} {
      @include pb($i);
    }
    .tl-pl-#{$i} {
      @include pl($i);
    }
    .tl-pr-#{$i} {
      @include pr($i);
    }
    .tl-mx-#{$i} {
      @include mx($i);
    }
    .tl-my-#{$i} {
      @include my($i);
    }
    .tl-px-#{$i} {
      @include px($i);
    }
    .tl-py-#{$i} {
      @include py($i);
    }
  }
}

@include col-x-spacing;
