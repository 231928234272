//
// Input groups
// --------------------------------------------------

// Base styles
// -------------------------
.tl-input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;

  // Undo padding and float of grid classes
  &[class*="tl-col-"] {
    float: none;
    padding-right: 0;
    padding-left: 0;
  }

  &-prepend {
    display: flex;
    margin-right: -1px;

    .tl-input-group-text {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  &-text {
    display: flex;
    align-items: center;
    padding: 0.375rem 0.75rem;
    margin-bottom: 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    text-align: center;
    white-space: nowrap;
    background-color: #e9ecef;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
  }

  .tl-form-control {
    position: relative;
    z-index: 2;
    float: left;
    width: 100%;
    margin-bottom: 0;

    &:focus {
      z-index: 3;
    }
  }
}

.tl-input-search-modules {
  background: #fc7723;
  border: 1px solid #ffffff;
  border-radius: 24px;

  .tl-input-group-text {
    background: transparent !important;
    border: none;
    color: #ffffff;
  }

  .tl-form-control {
    background: transparent !important;
    border: none;
    color: #ffffff !important;
  }
}

.tl-form-control {
  background: transparent !important;
  border: none;
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:focus-visible {
    outline-offset: unset;
    outline: none;
  }
}
