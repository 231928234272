mat-checkbox .mdc-form-field {
  font-family: $timeline-font;
  letter-spacing: normal;
}

mat-checkbox[timeline=orange] {
  &.mat-mdc-checkbox {
    --mdc-checkbox-state-layer-size: 18px;
    .mat-mdc-checkbox-touch-target {
      height: 38px;
      width: 38px;
    }
    .mdc-checkbox__background {
      width: var(--mdc-checkbox__size, 16px);
      height: var(--mdc-checkbox__size, 16px);
      border-color: $timeline-border-color !important;
      border-width: 1px;
      border-style: solid;
      border-radius: 4px;
      background-color: $timeline-background-color !important;
      svg {
        transform: scale(0.8);
      }
    }
    &.mat-mdc-checkbox-checked {
      .mdc-checkbox__background {
        background-color: $timeline-background-color-orange !important;
        border-color: $timeline-background-color-orange !important;
      }
    }
    label {
      padding-left: var(--mdc-checkbox__label-padding-left, 5px);
    }
    &.mat-mdc-checkbox-disabled {
      .mdc-checkbox__background {
        opacity: 0.38;
      }
    }
  }
  &.checked-square {
    &.mat-mdc-checkbox {
      &.mat-mdc-checkbox-checked {
        .mdc-checkbox__background {
          background-color: $timeline-color-white !important;
          svg {
            transform: scale(1);
            path {
              d: path("M5 5 H 19 V 19 H 5 Z");
              fill: #F87832;
              stroke-width: 0;
            }
          }
        }
      }
    }
  }
  .mdc-checkbox__ripple {
    display: none;
  }
  .mat-ripple {
    display: none;
  }
}

mat-checkbox[tl-lable-w300-f15] {
  &.mat-mdc-checkbox {
    .mdc-checkbox {
      margin-top: auto;
    }
    label {
      font-weight: 300;
      font-size: 15px;
    }
  }
}

mat-checkbox[tl-label-size=size11] {
  .mdc-form-field {
    label {
      font-size: 11px;
      line-height: 11px;
    }
  }
}

mat-checkbox[tl-label-color=gray] {
  .mdc-form-field {
    label {
      color: $timeline-color-darkgray;
    }
  }
}

mat-checkbox[tl-checkbox],
mat-checkbox[tl-round-checkbox] {
  .mdc-form-field {
    .mdc-checkbox {
      padding-right: var(--mdc-checkbox-label-distance, 2px);
    }
    .mat-mdc-checkbox-touch-target,
    .mdc-checkbox__ripple,
    .mat-ripple {
      display: none;
    }
  }
}

mat-checkbox[tl-round-checkbox] {
  --mdc-checkbox-selected-checkmark-color: var(--mdc-checkbox-selected-icon-color) !important;
  --mdc-checkbox-state-layer-size: 18px;
  .mdc-form-field {
    .mdc-checkbox {
      .mdc-checkbox__background {
        border-radius: 50%;
        background-color: transparent !important;
        .mdc-checkbox__mixedmark {
          border-radius: 50%;
          background-color: var(--mdc-checkbox-selected-checkmark-color);
          width: 10px;
          height: 10px;
          transition: transform ease 280ms,background-color ease 280ms;
          opacity: 1;
          border: none;
        }
      }
      :not(:checked) {
        .mdc-checkbox__background {
          .mdc-checkbox__mixedmark {
            opacity: 0;
          }
        }
      }
      svg {
        display: none;
      }
      .mdc-checkbox__native-control[disabled]:checked~.mdc-checkbox__background {
        border-color: var(--mdc-checkbox-selected-focus-icon-color);
      }
    }
  }
}

mat-checkbox[tl-round-checkbox=green-orange],
mat-checkbox[tl-checkbox=gray-orange] {
  --mdc-checkbox-unselected-focus-icon-color: rgba(0, 100, 0, 1) !important;
  --mdc-checkbox-unselected-icon-color: rgba(0, 100, 0, 1) !important;
  --mdc-checkbox-unselected-hover-icon-color: rgba(0, 100, 0, 0.8) !important;
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 100, 0, 1) !important;
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 100, 0, 0.4) !important;
  --mdc-checkbox-selected-focus-icon-color: rgba(252, 119, 35, 1) !important;
  --mdc-checkbox-selected-hover-icon-color: rgba(252, 119, 35, 0.8) !important;
  --mdc-checkbox-selected-pressed-icon-color: rgba(252, 119, 35, 1) !important;
  --mdc-checkbox-selected-icon-color: rgba(252, 119, 35, 1) !important;
  --mdc-checkbox-disabled-selected-icon-color: rgba(252, 119, 35, 0.4) !important;
}

mat-checkbox[tl-round-checkbox=gray-orange],
mat-checkbox[tl-checkbox=gray-orange] {
  --mdc-checkbox-unselected-focus-icon-color: rgba(128, 128, 128, 1) !important;
  --mdc-checkbox-unselected-icon-color: rgba(128, 128, 128, 1) !important;
  --mdc-checkbox-unselected-hover-icon-color: rgba(128, 128, 128, 0.8) !important;
  --mdc-checkbox-unselected-pressed-icon-color: rgba(128, 128, 128, 1) !important;
  --mdc-checkbox-disabled-unselected-icon-color: rgba(128, 128, 128, 0.4) !important;
  --mdc-checkbox-selected-focus-icon-color: rgba(252, 119, 35, 1) !important;
  --mdc-checkbox-selected-hover-icon-color: rgba(252, 119, 35, 0.8) !important;
  --mdc-checkbox-selected-pressed-icon-color: rgba(252, 119, 35, 1) !important;
  --mdc-checkbox-selected-icon-color: rgba(252, 119, 35, 1) !important;
  --mdc-checkbox-disabled-selected-icon-color: rgba(252, 119, 35, 0.4) !important;
}

input[type="checkbox"][timeline] {
  accent-color: $timeline-native-checkbox-orange;
}

mat-checkbox[tlCustomCheckbox] {
  .mdc-checkbox .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background {
    border-color: $timeline-border-color-orange !important;
  }

  .mdc-checkbox .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background, .mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate~.mdc-checkbox__background, .mdc-checkbox .mdc-checkbox__native-control[data-indeterminate=true]:enabled~.mdc-checkbox__background {
    border-color: $timeline-border-color-orange !important;
    background-color: $timeline-border-color-orange !important;
  }

  .mdc-checkbox .mdc-checkbox__native-control:enabled~.mdc-checkbox__background .mdc-checkbox__checkmark {
    transform: scale(.6) !important;
  }

  .mdc-checkbox__ripple {
    display: none;
  }

  .mat-ripple {
    display: none;
  }

  --mdc-checkbox-unselected-focus-icon-color: rgba(128, 128, 128, 1) !important;
  --mdc-checkbox-unselected-icon-color: rgba(128, 128, 128, 1) !important;
  --mdc-checkbox-unselected-hover-icon-color: rgba(128, 128, 128, 0.8) !important;
  --mdc-checkbox-unselected-pressed-icon-color: rgba(128, 128, 128, 1) !important;
  --mdc-checkbox-disabled-unselected-icon-color: rgba(128, 128, 128, 0.4) !important;
  --mdc-checkbox-selected-focus-icon-color: rgba(252, 119, 35, 1) !important;
  --mdc-checkbox-selected-hover-icon-color: rgba(252, 119, 35, 0.8) !important;
  --mdc-checkbox-selected-pressed-icon-color: rgba(252, 119, 35, 1) !important;
  --mdc-checkbox-selected-icon-color: rgba(252, 119, 35, 1) !important;
  --mdc-checkbox-disabled-selected-icon-color: rgba(252, 119, 35, 0.4) !important;
}
