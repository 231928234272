[mat-button], [mat-icon-button], [mat-raised-button], [mat-stroked-button], [mat-flat-button] {
  font-family: $timeline-font !important;
  --mdc-text-button-label-text-tracking: normal;
  &.mdc-button {
    padding: var(--mdc-button-padding-top, 0) var(--mdc-button-padding-right, 16px) var(--mdc-button-padding-bottom, 0) var(--mdc-button-padding-left, 16px);
    .mdc-button__label {
      white-space: var(--mdc-button__label-white-space, normal);
      display: var(--mdc-button__label-display, inherit);
      align-items: var(--mdc-button__label-align-items, inherit);
      line-height: var(--mdc-button__label-line-height, inherit);
      letter-spacing: var(--mdc-button__label-letter-spacing, normal);
    }
  }
  .mat-mdc-button-touch-target {
    display: var(--mdc-button-touch-target__display, inherit) !important;
    pointer-events: var(--mdc-button-touch-target__pointer-events, inherit);
  }
  .mat-mdc-button-ripple {
    display: var(--mdc-button-ripple__display, inherit);
  }
  .mat-mdc-button-persistent-ripple {
    display: var(--mdc-button-persistent-ripple__display, inherit);
  }
  &:active {
    .mat-mdc-button-persistent-ripple {
      &:before {
        z-index: var(--mat-text-button-pressed-state-layer-z-index, 0);
      }
    }
  }
}

.mat-mdc-button:has(i.ti) {
  padding: 0 var(--mat-text-button-with-icon-horizontal-padding, 8px);
}

[mat-button],
[mat-stroked-button] {
  &[align-items=center] {
    .mdc-button__label {
      display: flex;
      align-items: center;
    }
  }
}

[mat-icon-button] {
  &.mat-mdc-button-base {
    height: var(--mdc-icon-button-state-layer-size, 48px);
    width: var(--mdc-icon-button-state-layer-size, 48px);
    padding: var(--mdc-icon-button-state-layer-padding, 12px);
    display: var(--mdc-icon-button-state-layer-display, inline-block);
    align-items: var(--mdc-icon-button-state-layer-align, center);
    justify-content: var(--mdc-icon-button-state-layer-justify, center);
  }
  &.mat-mdc-icon-button {
    .mat-mdc-button-persistent-ripple {
      border-radius: var(--mdc-button-persistent-ripple__border-radius, 50%);
    }
  }
}

/* mat-button */
[mat-button][color=timeline-white]:not([disabled]) {
  color: white;
  background-color: $timeline-color;
}

[mat-button][color=timeline-white][disabled] {
  border: 2px solid $timeline-color-disabled-text;
}

[mat-button][color=timeline-white] mat-icon {
  margin-bottom: 3px;
}

[mat-button][color=warn-white]:not([disabled]) {
  color: white;
  background-color: $timeline-color-red;
}

[mat-button][color=warn-white][disabled] {
  border: 2px solid $timeline-color-disabled-text;
}

[mat-button][color=warn-white] mat-icon {
  margin-bottom: 3px;
}

/* raised*/
[mat-raised-button][color=primary] {
  background-color: $timeline-color;
}

[mat-raised-button][color=white-black] {
  color: black;
  background-color: white;
}

[mat-raised-button][color=themes] {
  color: white;
  background-color: $timeline-color;
}

[mat-raised-button][color=warn-white] {
  color: white;
  background-color: $timeline-color-red;
}

[mat-raised-button][color=warn-white] mat-icon {
  margin-bottom: 3px;
}

/* icon raised*/
[mat-icon-button][mat-raised-button][color=primary] {
  background-color: $timeline-color;
}

/* icon */
[mat-icon-button][color=black-white] {
  color: white;
  background-color: black;
}

/* extra-lightgray */
[mat-button][color=extra-lightgray] {
  background-color: $timeline-color-extra-light-gray;
}

[mat-button][color=extra-lightgray] mat-icon {
  margin-bottom: 3px;
}

/* stroked */

[mat-stroked-button][color=primary] {
  background-color: white;
  color: $timeline-color;
  border: 2px solid $timeline-color;
}

[mat-stroked-button][color=primary][disabled] {
  border: 2px solid $timeline-color-disabled-text;
}

[mat-stroked-button][color=white] {
  color: white;
  border: 2px solid white;
}

[mat-stroked-button][color=white][disabled] {
  border: 2px solid $timeline-color-disabled-text;
}

/* flat */
[mat-flat-button][color=primary] {
  background-color: $timeline-color;
  color: white;
}

[mat-button][color=timeline-white] {
  color: #ffffff !important;
  background-color: transparent !important;
}
[mat-button][color=timeline-white][disabled] {
  color: #d2d2d2 !important;
}

[mat-button][color=timeline-black-outlined] {
  color: #000000 !important;
  border-color: rgba(0, 0, 0, 0.25) !important;
}
[mat-button][color=timeline-black-outlined][disabled] {
  color: #575757 !important;
}

[mat-button][timeline],
[mat-raised-button][timeline] {
  font-weight: 700;
  border-radius: 16px;
  --mdc-button-padding-right: 16px;
  --mdc-button-padding-left: 16px;
  min-width: auto;
  background-color: $timeline-background-color-light;
  &.mat-mdc-button-disabled {
    opacity: 0.3;
  }
  .mdc-button__label {
    line-height: 1;
  }
  &.mat-mdc-button[disabled][disabled] {
    opacity: 0.3;
  }
  &.warning {
    pointer-events: unset;
    .mdc-button__label {
      &:before {
        content: "!";
        color: #ff0000;
        font-weight: bold;
        font-size: 1.4em;
        margin-right: 2px;
      }
    }
  }
  &[timeline*="normal"] {
    font-weight: normal;
  }
  &[timeline*="transparent"] {
    background-color: transparent;
  }
  &[timeline*="icon-text"] {
    &.mat-mdc-button {
      padding: 0 16px 0 8px;
    }
    .mdc-button__label {
      display: flex;
      align-items: center;
      gap: 5px;
    }
  }
}

[mat-button][timeline*="orange"],
[mat-button].timeline-orange,
[mat-raised-button][timeline=orange] {
  background-color: $timeline-border-color-orange;
  &:not(.disabled) {
    color: white;
  }
}

[mat-button][timeline][icon-button],
[mat-raised-button][timeline][icon-button] {
  &.mat-mdc-button>.mat-icon {
    margin: 0;
  }
}

[mat-mini-fab] {
  .mat-mdc-button-touch-target {
    display: var(--mdc-button-touch-target__display, inherit);
    pointer-events: var(--mdc-button-touch-target__pointer-events, inherit);
  }
}

[mat-mini-fab][timeline] {
  .mat-mdc-button-touch-target {
    display: none;
  }
}

[mat-mini-fab][timeline=orange] {
  &.mat-mdc-mini-fab {
    background-color: $timeline-background-color-orange;
  }
}

[mat-mini-fab][flat] {
  &.mat-mdc-mini-fab {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  }
}

[mat-mini-fab][timeline=lightgrey] {
  &.mat-mdc-mini-fab {
    background-color: $timeline-color-lightgrey;
  }
}

[mat-mini-fab][timeline=more-menu],
[mat-mini-fab][timeline=toolbar] {
  &.mat-mdc-mini-fab {
    width: auto;
    height: auto;
    padding: 5px;
    box-shadow: none;
    background-color: transparent;
    color: $timeline-tree-active-mode-switcher;
    &:active,
    &:focus:active {
      box-shadow: none;
    }
    &.cdk-focused,
    &.cdk-mouse-focused {
      box-shadow: none;
    }
    &[disabled][disabled] {
      opacity: 0.3;
    }
    .mat-mdc-button-ripple {
      display: var(--mdc-button-ripple__display, inherit);
    }
  }

  &:hover {
    &.mat-mdc-mini-fab {
      background-color: $timeline-background-color-hover;
    }
  }
  &.align-right {
    margin-left: auto;
    margin-right: 5px;
  }
}

[mat-mini-fab][tl-color=orange] {
  &.mat-mdc-mini-fab {
    &:not([disabled][disabled]) {
      color: $timeline-border-color-orange;
      &:hover {
        &.mat-mdc-mini-fab {
          background-color: $timeline-background-color-hover-light;
        }
      }
    }
  }
}

[mat-icon-button][timeline=orange] {
  color: white;
  background-color: $timeline-background-color-orange;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  --mdc-icon-button-state-layer-size: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  .mat-mdc-button-touch-target {
    display: none;
  }
}

[mat-button][h44] {
  height: 44px;
}

