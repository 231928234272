mat-datepicker-toggle[timeline] {
  --mdc-icon-button-icon-size: 1em;
  .mat-mdc-icon-button {
    &.mat-mdc-button-base {
      width: 1em;
      height: 1em;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      margin-top: 0.75em;
    }
    .mat-mdc-button-touch-target {
      pointer-events: none;
    }
  }
}

app-date-time-picker {
  .container {
    display: block;
    font-size: 16px;
    .date-field {
      width: 100%;
      &.hide-date {
        line-height: 32px;
      }
    }
    mat-form-field[timeline] {
      .mdc-text-field {
        &.mat-mdc-text-field-wrapper {

          .mat-mdc-form-field-focus-overlay {
          }

          .mat-mdc-form-field-flex {

            .mat-mdc-floating-label {

              &.mdc-floating-label--float-above {

              }
            }
          }

          .mat-mdc-form-field-infix {

            .mat-mdc-input-element {

            }

            label {
              font-size: 0.85em;;
              pointer-events: none;
            }
          }

          .mat-mdc-form-field-icon-suffix {
            display: flex;
            padding: 0;
            align-items: center;
            margin-top: 0.6em;
            gap: 5px;
            cursor: pointer;
            .mat-mdc-icon-button.clear-button {
              top: 0;
            }
            .mat-mdc-icon-button.mat-mdc-button-base[time-picker-button] {
              width: 1em;
              height: 1em;
              display: flex;
              align-items: center;
              justify-content: center;
              top: 0;
              &:hover {
                background-color: unset;
              }
              i.ti,
              mat-icon {
                font-size: 1em;
              }
              .mat-mdc-button-touch-target {
                pointer-events: none;
              }
              &:hover {
                .mat-ripple {
                  display: block;
                  background: #000;
                  opacity: .04;
                  width: 1.3em;
                  height: 1.3em;
                  top: -0.16em;
                  left: -0.15em;
                }
              }
            }
          }

          &.mdc-text-field--disabled {
            .mdc-line-ripple {
              &:before {
                border-bottom-color: rgba(0, 0, 0, 1);
              }
            }
          }
        }
      }
      .mat-mdc-form-field-subscript-wrapper {
        .mat-mdc-form-field-hint-wrapper {
          .error-hint {
            color: $timeline-color-red;
          }
        }
      }
      .input-wrapper {
        min-height: var(--mat-mdc-input-element__min-height, 1.5em);
        input.mat-mdc-input-element:disabled {
          color: $timeline-color-black;
          cursor: pointer;
        }
        &.disabled {
          color: $timeline-text-gray;
          cursor: default;
          pointer-events: none;
          input.mat-mdc-input-element:disabled {
            color: $timeline-text-gray;
            cursor: default;
          }
        }
      }
      &.float-label-never {
        .mdc-text-field.mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label.mdc-floating-label--float-above {
          display: none;
        }
      }
    }
  }
}

app-time-picker {
  [time-picker-label] {
    &.mat-mdc-button {
      color: $timeline-color-black;
      padding: 0;
      min-width: fit-content;
      .mdc-button__label {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
      }
    }
  }
}

app-date-time-picker[roundedTheme] {
  mat-form-field[timeline] {
    border: 1px solid;
    border-color: $timeline-border-color;
    border-radius: 24px;
    padding: 5px 15px;
    .mdc-text-field {
      &.mat-mdc-text-field-wrapper {

        .mat-mdc-form-field-flex {
          align-items: center;
          height: 30px;

          .mat-mdc-form-field-icon-prefix {
            mat-datepicker-toggle[timeline] .mat-mdc-icon-button.mat-mdc-button-base {
              margin-top: 0;
            }
          }

          .mat-mdc-form-field-infix {
            padding: 0;
          }

          .mat-mdc-form-field-icon-suffix {
            margin-top: 0;
            gap: 8px;
          }
        }
        .mdc-line-ripple {
          display: none;
        }
      }
    }
    .mat-mdc-form-field-subscript-wrapper {
      display: none;
    }
  }
}

app-date-time-picker[shrink-date-picker=h45] {
  .container {
    height: 45px;
  }
}

app-date-time-picker[picker-size=date] {
  .container {
    width: 85px;
    mat-form-field[timeline] {
      .mdc-text-field {
        &.mat-mdc-text-field-wrapper {
          .mat-mdc-form-field-flex {
            .mat-mdc-form-field-icon-prefix {
              display: none;
            }
          }
        }
      }
    }
  }
}

app-date-time-picker[picker-size=time] {
  .container {
    width: 65px;
    mat-form-field[timeline] {
      .mdc-text-field {
        &.mat-mdc-text-field-wrapper {
          .mat-mdc-form-field-flex {
            .mat-mdc-form-field-icon-prefix {
              display: none;
            }
          }
        }
      }
    }
  }
}

app-date-time-picker[picker-size=thin-time] {
  .container {
    mat-form-field[timeline] {
      .mdc-text-field {
        &.mat-mdc-text-field-wrapper {

          .mat-mdc-form-field-flex {

            .mat-mdc-form-field-icon-prefix {
              display: none;
            }

            .mat-mdc-form-field-infix {
              display: none;
            }

            .mat-mdc-form-field-icon-suffix {
              margin-top: 0;

              [time-picker-button] {
                display: none;
              }
            }
          }
          .mdc-line-ripple {
            display: none;
          }

        }
      }
      .mat-mdc-form-field-subscript-wrapper {
        display: none;
      }
    }
  }
}

app-date-time-picker[time-color=white] {
  app-time-picker {
    .mat-mdc-button {
      color: white;
    }
  }
}

mat-datepicker-toggle[tl-orange] {
  .mat-mdc-icon-button {
    &.mat-mdc-button-base {
      --mdc-icon-button-state-layer-size: 40px;
      color: white;
      background-color: $timeline-background-color-orange;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
    }
    .mat-mdc-button-touch-target {
      pointer-events: none;
    }
  }
}

.mat-datepicker-content {
  border-radius: 16px !important;
  --mat-datepicker-calendar-date-selected-state-background-color: #F87832;
}
.mat-calendar-period-button {
  border-radius: 16px !important;
}

mat-datepicker-toggle {
  --mat-datepicker-toggle-active-state-icon-color: #F87832;
}
