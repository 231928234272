mat-slide-toggle .mdc-form-field {
  font-family: $timeline-font;
  letter-spacing: normal;
  display: var(--mdc-form-field-display, inline-flex);
}


mat-slide-toggle[timeline=orange] {
  &.mat-mdc-slide-toggle {
    .mdc-switch {
      --mdc-switch-track-height: 18px;
      --mat-switch-with-icon-handle-size: 16px;
      --mat-switch-pressed-handle-size: 16px;
      --mat-switch-unselected-handle-size: 16px;
      --mat-switch-selected-handle-size: 16px;
      --mdc-switch-handle-width: 16px;
      --mdc-switch-handle-height: 16px;
      --mdc-switch-track-shape: 8px;
      --mdc-switch-disabled-track-opacity: 0.25;
      .mdc-switch__track {
        &:before {
          background-color: $timeline-background-color-switch-off !important;
        }
        &:after {
          background-color: $timeline-border-color-orange !important;
        }
      }

      .mdc-switch__handle-track {
        .mdc-switch__handle {
          box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
          &:before {
            left: 1px;
          }
          &:after {
            background-color: $timeline-color-white !important;
            left: 1px;
          }
        }

        .mdc-switch__shadow {
          display: none;
        }

        .mdc-switch__ripple {
          display: none;
        }

        .mdc-switch__icons {
          svg.mdc-switch__icon--on {
            fill: $timeline-border-color-orange;
            width: 14px;
            height: 14px;
            margin-right: 2px;
          }
          svg.mdc-switch__icon--off {
            fill: $timeline-background-color-switch-off;
          }
        }
      }

      &.mdc-switch--checked {
        .mdc-switch__handle-track {
          .mdc-switch__handle {
            &:before,
            &:after {
              left: -1px;
            }
          }
        }
      }

      &.mdc-switch--disabled {
        .mdc-switch__handle-track {
          .mdc-switch__handle {
            box-shadow: none;
          }
        }
      }
    }

    label {
      padding-left: 8px;
      line-height: 1;
    }
  }
}

/* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. */
.mat-mdc-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
  background-color: $timeline-color;
}

/* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. */
.mat-mdc-slide-toggle.mat-checked.mat-disabled .mat-slide-toggle-thumb {
  background-color: $timeline-color-light-rgb;
}

/* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. */
.mat-mdc-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
  background-color: $timeline-color-light;
}

/* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. */
.mat-mdc-slide-toggle.mat-checked.mat-disabled .mat-slide-toggle-bar {
  background-color: $timeline-color-light;
}

.mat-mdc-slide-toggle.mat-checked .mat-ripple-element {
  background-color: $timeline-color-light;
}

/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
.mat-checkbox-checked.mat-accent .mat-checkbox-background, .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
  background-color: $timeline-color;
}

[mat-slide-toggle][color=primary] {
  /* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. */
  .mat-slide-toggle-content {
    font-family: $timeline-font;
  }
}

/* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. */
mat-slide-toggle[color=primary-white] {
  /* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. */
  .mat-slide-toggle-content {
    font-family: $timeline-font;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. */
  &.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
    background-color: $timeline-color-white;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. */
  &.mat-checked.mat-disabled .mat-slide-toggle-bar {
    opacity: 0.3;
  }
}

/*
!* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. *!
!* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. *!
mat-slide-toggle[shirnk] {
  !* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. *!
  .mat-slide-toggle-content {
    white-space: break-spaces;
    line-height: 16px;
  }
}

!* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. *!
!* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. *!
mat-slide-toggle[hideRipple] {
  !* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. *!
  .mat-slide-toggle-ripple {
    display: none;
  }
}
*/

/* mat-slide-toggle timeline*/
/* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. */
/*
[timeline].mat-mdc-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
  background-color: $timeline-border-color-orange;
}
*/

/* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. */
/*
[timeline].mat-mdc-slide-toggle.mat-checked.mat-disabled .mat-slide-toggle-bar {
  background-color: $timeline-border-color-orange;
}
*/

/*
!* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. *!
!* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. *!
!* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. *!
!* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. *!
[timeline] .mat-slide-toggle-label {
  !* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. *!
  !* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. *!
  !* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. *!
  .mat-slide-toggle-bar {
    height: 18px;
    width: 34px;
    !* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. *!
    !* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. *!
    .mat-slide-toggle-thumb-container {
      width: auto;
      height: auto;
      top: 1px;
      left: 1px;
      !* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. *!
      .mat-slide-toggle-thumb {
        height: 16px;
        width: 16px;
      }
      .mat-ripple {
        display: none;
      }
    }
  }
}

!* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. *!
[timeline].mat-mdc-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
  background-color: $timeline-color-white;
}

!* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version. *!
[timeline].mat-mdc-slide-toggle.mat-checked.mat-disabled .mat-slide-toggle-thumb {
  background-color: $timeline-color-white;
}

!* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. *!
[timeline].mat-checkbox-checked.mat-accent .mat-checkbox-background, .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
  background-color: $timeline-color-white;
}
*/

