@mixin MQ($canvas) {
  @if $canvas == SM {
    @media only screen and (min-width: $SM) {
      @content;
    }
  } @else if $canvas == MD {
    @media only screen and (min-width: $MD) {
      @content;
    }
  } @else if $canvas == LG {
    @media only screen and (min-width: $LG) {
      @content;
    }
  } @else if $canvas == XL {
    @media only screen and (min-width: $XL) {
      @content;
    }
  }
}

@mixin MQ-max($canvas) {
  @if $canvas == SM {
    @media only screen and (max-width: ($SM - 0.2px)) {
      @content;
    }
  } @else if $canvas == MD {
    @media only screen and (max-width: ($MD - 0.2px)) {
      @content;
    }
  } @else if $canvas == LG {
    @media only screen and (max-width: ($LG - 0.2px)) {
      @content;
    }
  } @else if $canvas == XL {
    @media only screen and (max-width: ($XL - 0.2px)) {
      @content;
    }
  }
}
