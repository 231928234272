mat-slider[timeline] {
  --mdc-slider-inactive-track-height: 2px;
  --mdc-slider-active-track-height: 2px;
  mat-slider-visual-thumb {
    --mdc-slider-handle-height: 16px;
    --mdc-slider-handle-width: 16px;
    .mdc-slider__thumb-knob {
      background-color: $timeline-background-color-orange !important;
      border-color: $timeline-background-color-orange !important;;
    }
    .mat-ripple {
      display: none;
    }
  }
  .mdc-slider__track--active_fill {
    border-color: $timeline-border-color-orange;
  }
}
mat-slider[timeline="correct"] {
  --mdc-slider-inactive-track-height: 2px;
  --mdc-slider-active-track-height: 2px;
  mat-slider-visual-thumb {
    --mdc-slider-handle-height: 16px;
    --mdc-slider-handle-width: 16px;
    .mdc-slider__thumb-knob {
      background-color: transparent !important;
      border-color: transparent !important;
    }
    .mat-ripple {
      display: none;
    }

    background-position: center;
    background-repeat: no-repeat;
    background-image: url("../../../assets/images/icons/shapes/tabler-circle-check-filled.svg");

  }
  .mdc-slider__track--active_fill {
    border-color: #16A34A;
    width: 99%;
  }

  .mdc-slider__track--inactive {
    background: #DEE2E6;
  }

}

mat-slider[timeline="wrong"] {
  --mdc-slider-active-track-height: 0px;

  mat-slider-visual-thumb {
    --mdc-slider-handle-height: 20px;
    --mdc-slider-handle-width: 20px;
    .mdc-slider__thumb-knob {
      background-color: transparent !important;
      border-color: transparent !important;
    }

    background-position: center;
    background-repeat: no-repeat;
    background-image: url("../../../assets/images/icons/shapes/tabler-icon-circle-x-filled.svg");
  }

}
