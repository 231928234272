@import '../material/tl-button.scss';
@import '../material/tl-icon.scss';
@import '../material/tl-radiogroup.scss';
@import '../material/tl-checkbox.scss';
@import '../material/tl-menu';
@import '../material/tl-paginator';
@import '../material/tl-tooltip';
@import '../material/tl-form-field';
@import '../material/tl-badge';
@import '../material/tl-snackbar';
@import '../material/tl-select.scss';
@import '../material/tl-toggle-group';
@import '../material/tl-dialog';
@import '../material/tl-bottom-sheet';
@import '../material/tl-button-toggle-group';
@import '../material/tl-slide-toggle';
@import '../material/tl-expansion-panel';
@import '../material/tl-date-time-picker';
@import '../material/tl-slider';
@import '../material/tl-chip';
@import '../material/tl-tree';

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
mat-form-field.slim .mat-form-field-infix {
  border-top: 0;
  padding-top: 0;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
mat-form-field.slim .mat-form-field-label {
  top: 15px;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
mat-form-field.slim-border .mat-form-field-infix {
  border-top: 0;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
mat-form-field.slim-border .mat-form-field-label {
  top: 15px;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
mat-form-field.slim-border-only .mat-form-field-infix {
  border-top: 0;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
mat-form-field[label-color=white] .mat-form-field-label {
  color: white;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
mat-form-field[label-color=white] .mat-form-field-label {
  color: white;
  background-color: pink;
}

/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
mat-select[select-color=white].mat-select-disabled {
  /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
  /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
  .mat-select-trigger {
    /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
    .mat-select-value {
      color: $timeline-color-white-06;
    }
  }
  /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
  /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
  .mat-select-arrow-wrapper {
    /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
    .mat-select-arrow {
      color: $timeline-color-white-06;
    }
  }
}
/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
mat-select[select-color=white]:not(.mat-select-disabled) .mat-select-trigger {
  /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
  .mat-select-value {
    color: white;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
  /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
  .mat-select-arrow-wrapper {
    /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
    .mat-select-arrow {
      color: white;
    }
  }
}

app-html-text-editor {
  &.with-pin {
    .html-text-editor-textarea {
      padding-top: 30px !important;
    }
  }
}

.disable-div {
  color: rgba(0,0,0,.26);
  cursor: default;
  pointer-events: none;
}

.disable-div-with-opacity {
  color: rgba(0,0,0,.26);
  opacity: 0.3;
  cursor: default;
  pointer-events: none;
}


mat-form-field[heigth=reduced] .mat-form-field-wrapper {
  padding-bottom: 0;
  .mat-form-field-flex {
    .mat-form-field-infix {
      padding: 0.2em 0 0.7em 0;
    }
  }
}
