.mat-badge.mat-badge-green .mat-badge-content {
  background-color: $timeline-color-green;
}

.mat-badge-text-orange {
  .mat-badge-content {
    color: $timeline-border-color-orange !important;
    background-color: $timeline-background-color-light !important;
  }
}

.mat-badge-bg-orange {
  .mat-badge-content {
    color: $timeline-background-color-light !important;
    background-color: $timeline-border-color-orange !important;
  }
}

.mat-badge-top-right-12 {
  .mat-badge-content {
    top: -4px !important;
    left: calc(100% + 3px) !important;
  }
}
