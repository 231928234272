mat-tree[timeline] {
  mat-tree-node {
    &.mat-tree-node {
      gap: 5px;
      min-height: 35px;
      border-radius: 16px;
      &:hover {
        background-color: $timeline-background-color-hover;
      }
    }
    .mat-mdc-icon-button {
      &.mat-mdc-button-base {
        width: auto;
        height: auto;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .mat-mdc-button-touch-target {
        display: none;
        height: 0;
        width: 0;
      }
    }
  }
}
