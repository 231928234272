/* mat-icons*/
i.material-icons[color=pure-black],
mat-icon[color=pure-black] {
  color: black;
}

i.material-icons[color=black],
mat-icon[color=black] {
  color: $timeline-menu-icon-color;
}

i.ti[color=white],
i.material-icons[color=white],
mat-icon[color=white] {
  color: white;
}

i.ti[color=orange],
i.material-icons[color=orange],
.material-icons[color=orange] {
  color: $timeline-border-color-orange !important;
}

i.material-icons[color=primary],
.material-icons[color=primary] {
  color: $timeline-color;
}

.material-icons[color=gray] {
  color: $timeline-color-darkgray;
}

i.material-icons.disabled,
i.ti.disabled,
mat-icon.disabled {
  color: $timeline-text-lightgray !important;
  cursor: default;
  pointer-events: none;
}

i.ti,
i.material-icons,
i.material-icons-outlined,
mat-icon,
.material-icons {
  &.s-10 {
    font-size: 10px;
    width: 10px;
    height: 10px;
    min-width: 10px;
    min-height: 10px;
    line-height: 10px;
  }
  &.s-14 {
    font-size: 14px;
    width: 14px;
    height: 14px;
    min-width: 14px;
    min-height: 14px;
    line-height: 14px;
  }
  &.s-16 {
    font-size: 16px;
    width: 16px;
    height: 16px;
    min-width: 16px;
    min-height: 16px;
    line-height: 16px;
  }
  &.s-18 {
    font-size: 18px;
    width: 18px;
    height: 18px;
    min-width: 18px;
    min-height: 18px;
    line-height: 18px;
  }
  &.s-19 {
    font-size: 19px;
    width: 19px;
    height: 19px;
    min-width: 19px;
    min-height: 19px;
    line-height: 19px;
  }
  &.s-20 {
    font-size: 20px;
    width: 20px;
    height: 20px;
    min-width: 20px;
    min-height: 20px;
    line-height: 20px;
  }
  &.s-21 {
    font-size: 21px;
    width: 21px;
    height: 21px;
    min-width: 21px;
    min-height: 21px;
    line-height: 21px;
  }
  &.s-22 {
    font-size: 22px!important;
    width: 22px!important;
    height: 22px!important;
    min-width: 22px!important;
    min-height: 22px!important;
    line-height: 22px!important;
  }
  &.s-24 {
    font-size: 24px;
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;
    line-height: 24px;
  }
  &.s-25 {
    font-size: 25px!important;
    width: 25px!important;
    height: 25px!important;
    min-width: 25px!important;
    min-height: 25px!important;
    line-height: 25px!important;
  }
  &.s-26 {
    font-size: 26px!important;
    width: 26px!important;
    height: 26px!important;
    min-width: 26px!important;
    min-height: 26px!important;
    line-height: 26px!important;
  }
  &.s-28 {
    font-size: 28px!important;
    width: 28px!important;
    height: 28px!important;
    min-width: 28px!important;
    min-height: 28px!important;
    line-height: 28px!important;
  }
  &.s-30 {
    font-size: 30px!important;
    width: 30px!important;
    height: 30px!important;
    min-width: 30px!important;
    min-height: 30px!important;
    line-height: 30px!important;
  }
  &.s-32 {
    font-size: 32px!important;
    width: 32px!important;
    height: 32px!important;
    min-width: 32px!important;
    min-height: 32px!important;
    line-height: 32px!important;
  }
  &.s-36 {
    font-size: 36px!important;
    width: 36px!important;
    height: 36px!important;
    min-width: 36px!important;
    min-height: 36px!important;
    line-height: 36px!important;
  }
  &.s-48 {
    font-size: 48px!important;
    width: 48px!important;
    height: 48px!important;
    min-width: 48px!important;
    min-height: 48px!important;
    line-height: 48px!important;
  }
  &.s-64 {
    font-size: 64px!important;
    width: 64px!important;
    height: 64px!important;
    min-width: 64px!important;
    min-height: 64px!important;
    line-height: 64px!important;
  }
}

mat-icon.svg-gray-first-circle {
  svg {
    circle:first-child {
      fill: $timeline-border-color;
    }
  }
  &:hover {
    opacity: 0.6;
    circle:first-child {
      fill: $timeline-background-color-orange;
    }
  }
}
mat-icon.svg-orange-first-circle {
  svg {
    circle:first-child {
      fill: $timeline-background-color-orange;
    }
  }
  &:hover {
    opacity: 1;
    circle:first-child {
      fill: $timeline-background-color-orange;
    }
  }
}

mat-icon[timeline-type-background-none] {
  svg {
    rect {
      fill: none;
    }
  }
}

mat-icon[tl-mat-icon].active {
  svg {
    fill: var(--tl-mat-icon-svg__fill);
  }
}

i.ti {
  font-size: 24px;
}

