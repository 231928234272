.mat-mdc-radio-group {
  .mat-mdc-radio-button {
    .mdc-form-field {
      font-family: $timeline-font;
      letter-spacing: normal;
    }
  }
}

mat-radio-group[timeline=orange] {
  .mat-mdc-radio-button {
    --mdc-radio-state-layer-size: 20px;

    .mdc-form-field {
      .mdc-radio {
        .mat-mdc-radio-touch-target {
          width: 38px;
          height: 38px;
        }

        .mdc-radio__background {
          &:before {
          }
          .mdc-radio__outer-circle {
            border-color: $timeline-border-color-orange !important;
          }

          .mdc-radio__inner-circle {
            border-color: $timeline-border-color-orange !important;
          }
        }

        .mat-ripple {
          .mat-ripple-element {
            background-color: $timeline-border-color-orange;
          }
        }
      }

      &.mat-mdc-radio-checked {
        .mdc-radio__background {
          &:before {
            background-color: $timeline-border-color-orange !important;
          }
        }
      }
      label {
        padding-left: var(--mdc-radio__label-padding-left, 5px);
        letter-spacing: normal;
      }
    }
  }
}

mat-radio-group[timeline=orange][tl-size-selector] {
  --mdc-radio__label-padding-left: 8px;
  .mat-mdc-radio-button .mdc-form-field .mdc-radio {
    margin-top: 10px;
    .mdc-radio__background .mdc-radio__outer-circle {
      border-color: $radio-button-selector-border-color !important;
    }
  }
}

mat-radio-group[timeline=orange][tl-position-selector] {
  .mat-mdc-radio-button .mdc-form-field {
    .mdc-radio {
      width: 26px;
      height: 26px;
      input {
        top: calc(50% - 10px);
        left: calc(50% - 10px);
      }
      .mdc-radio__background {
        &:before {
          content: none;
        }
        .mdc-radio__outer-circle {
          border-radius: 3px;
          border-color: $radio-button-selector-border-color !important;
          width: 26px;
          height: 26px;
          border-style: dotted;
          border-width: 1px;
        }

        .mdc-radio__inner-circle {
          top: calc(50% - 7px);
          left: calc(50% - 7px);
        }
      }
    }
    label {
      display: none;
    }
    .mat-ripple {
      display: none;
    }
  }

  .mat-mdc-radio-button.left-top .mdc-form-field .mdc-radio .mdc-radio__background .mdc-radio__outer-circle {
    border-left-style: solid;
    border-left-width: 3px;
    border-top-style: solid;
    border-top-width: 3px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  .mat-mdc-radio-button.top .mdc-form-field .mdc-radio .mdc-radio__background .mdc-radio__outer-circle {
    border-top-style: solid;
    border-top-width: 3px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  .mat-mdc-radio-button.right-top .mdc-form-field .mdc-radio .mdc-radio__background .mdc-radio__outer-circle {
    border-right-style: solid;
    border-right-width: 3px;
    border-top-style: solid;
    border-top-width: 3px;
    border-top-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  .mat-mdc-radio-button.left .mdc-form-field .mdc-radio .mdc-radio__background .mdc-radio__outer-circle {
    border-left-style: solid;
    border-left-width: 3px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  .mat-mdc-radio-button.center .mdc-form-field .mdc-radio .mdc-radio__background .mdc-radio__outer-circle {
    border-style: solid;
    border-width: 2px;
  }

  .mat-mdc-radio-button.right .mdc-form-field .mdc-radio .mdc-radio__background .mdc-radio__outer-circle {
    border-right-style: solid;
    border-right-width: 3px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  .mat-mdc-radio-button.left-bottom .mdc-form-field .mdc-radio .mdc-radio__background .mdc-radio__outer-circle {
    border-left-style: solid;
    border-left-width: 3px;
    border-bottom-style: solid;
    border-bottom-width: 3px;
    border-bottom-right-radius: 5px;
    border-top-left-radius: 5px;
  }

  .mat-mdc-radio-button.bottom .mdc-form-field .mdc-radio .mdc-radio__background .mdc-radio__outer-circle {
    border-bottom-style: solid;
    border-bottom-width: 3px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  .mat-mdc-radio-button.right-bottom .mdc-form-field .mdc-radio .mdc-radio__background .mdc-radio__outer-circle {
    border-right-style: solid;
    border-right-width: 3px;
    border-bottom-style: solid;
    border-bottom-width: 3px;
    border-bottom-left-radius: 5px;
    border-top-right-radius: 5px;
  }
}

mat-radio-group[tl-multiline-label=black] {
  .mat-mdc-radio-button.mat-radio-button-item {
    .mdc-form-field {
      .mdc-radio {
        align-self: flex-start;
      }
      label {
        width: 100%;
        display: flex;
        flex-direction: column;
        font-weight: 300;
        font-size: 16px;

        .radio-button-item {
          line-height: 1em;
          padding-top: 2px;

          .item-title {
            display: flex;
            align-items: center;
            position: relative;

            .item-icon {
              color: $timeline-color-orange;
              position: absolute;
              right: -27px;
              top: 4px;
            }

            .item-icon-s {
              color: $timeline-color-orange;
              position: absolute;
              right: -7px;
              top: 4px;
            }
          }

          .item-title-hint {
            font-style: italic;
            color: $timeline-color-black;
            white-space: normal;
            font-size: 13px;
          }
        }
      }
    }
  }
}

mat-radio-group[tl-lable-w300-f16] {
  .mat-mdc-radio-button {
    .mdc-form-field {
      label {
        font-size: 16px;
        font-weight: 300;
      }
    }
  }
}

.vertical-radio-group {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  &.compact {
    gap: 0.3em;
  }
  &.slim {
    gap: 0;
  }
  &.thin {
    gap: 0.1em;
    .mat-mdc-radio-button .mdc-radio {
      --mdc-radio-state-layer-size: 34px;
    }
  }
}

.padding-x-12 {
  padding: 0 12px;
}

.reverse-radio-group {
  .mdc-form-field {
    width: 100%;
    flex-direction: row-reverse;

    label {
      margin-left: auto;
      margin-right: 15px;
    }
  }
}

.horizontal-radio-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  .mat-mdc-radio-button {
    margin-right: 1em;
  }
  &.compact {
    .mat-mdc-radio-button {
      margin-right: 0.5em;
    }
  }
}

mat-radio-button[wrap-by-space] {
  .mdc-form-field {
    label {
      white-space: pre-line;
    }
  }
}

mat-radio-button[button-align=flex-start] {
  .mdc-radio {
    align-self: flex-start;
  }
}
