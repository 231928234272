/* ------  education styles ------ */

.education-settings,
.education-modal {
  .mat-mdc-dialog-container {
    .mdc-dialog__surface {
      padding: 0;
      border-radius: 20px;

      h2 {
        padding-left: 32px;
      }

      .mdc-dialog__title {
        margin: 0 0 20px;
        padding: 0;
        &:before {
          content: none;
        }
      }

      .mat-mdc-dialog-content {
        padding-bottom: 0;
      }

      .mat-mdc-dialog-actions {
        padding: 16px 24px;
        justify-content: flex-end;
      }

      mat-form-field {

        .mdc-text-field {
          padding-left: 0;
          padding-right: 0;
        }

        .mdc-text-field--filled:not(.mdc-text-field--disabled) {
          background-color: transparent;
          padding: 0;
        }

        .mat-mdc-form-field-focus-overlay {
          display: none;
        }

        .mat-mdc-form-field-hint-wrapper,
        .mat-mdc-form-field-error-wrapper {
          padding: 0;
        }

        .mdc-text-field--filled:not(.mdc-text-field--disabled) {
          background-color: transparent;
          padding: 0;
        }

        .mat-mdc-form-field-focus-overlay {
          display: none;
        }

        .mdc-text-field--disabled {
          background-color: transparent;
          .mdc-line-ripple {
            &::before {
              background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
              background-size: 4px 100%;
              background-repeat: repeat-x;
              background-position: 0;
              height: 1px;
              border-bottom-style: none;
            }
          }
        }

        .mat-mdc-form-field-error-wrapper {
          .mat-mdc-form-field-error {
            line-height: 2em;
            &:before {
              content: none;
            }
          }
        }

      }

      .mat-mdc-list-item {
        .mdc-list-item__primary-text {
          color: #ffffff;
          padding-left: 32px !important;
          font-style: normal;
          font-size: 14px;
          line-height: 17px;
        }
      }

      .mdc-list-item--disabled {
        background: $timeline-border-color-orange;
        .mdc-list-item__primary-text {
          color: #ffffff;
        }
      }

      .mdc-list-item--disabled {
        background: $timeline-border-color-orange;
        color: #ffffff;
      }

    }

    .yes-btn,
    .cancel-btn {
      padding: 0 16px;
    }
  }
}

.education-modal {
  .mat-mdc-dialog-container {
    .mdc-dialog__surface {
      padding: 24px;
      .mat-mdc-dialog-content {
        display: block;
        padding: 0 24px;
        margin: 0 -24px;
        >:first-child,
        >:last-child {
          margin-bottom: revert;
        }
      }
      .mat-mdc-dialog-actions {
        padding: 8px 0 0 0;
        min-height: auto;
      }
    }
    .mdc-dialog__surface {
      mat-form-field {
        width: 100%;
      }
    }
  }
}

lib-modules-form-permissions,
lib-learning-paths-form,
lib-user-selector,
app-curriculum-settings-permissions {
  .mat-mdc-chip {
    .mdc-evolution-chip__text-label {
      display: flex;
      align-items: center;
    }
  }
}

lib-tag-chip {
  mat-form-field {
    width: 100%;
    .mdc-line-ripple {
      display: none;
    }
  }
}

lib-modules-list,
lib-modules-selector {
  .mdc-data-table__cell,
  .mdc-data-table__header-cell {
    padding: 0;
  }
}

lib-tag-chip,
lib-modules-list,
lib-user-selector,
lib-modules-selector {
  .mdc-evolution-chip-set .mdc-evolution-chip-set__chips {
    margin-left: 0;
    margin-right: 0;
  }
}

.industry-selector {
  .industry-selector-radio-group {
    display: flex;
    flex-direction: column;
    margin: 15px;
    align-items: flex-start;
  }

  .industry-selector-radio-button {
    margin: 5px;
  }
}

.curriculum-settings {
  .curriculum-settings-container {
    padding: 0 15px;
    margin: 0;
    overflow: hidden;

    h2 {
      padding-left: 32px;
    }

    .mat-mdc-dialog-content {
      max-height: 75vh;
    }

    .route-container {
      display: flex;
      flex-direction: column;
      height: 100%;
      padding: 15px 0;
      overflow-y: hidden;

      .route-inner-container {
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        padding: 20px;
      }
    }
  }
}

.fields-settings {

  .fields-settings-container {
    padding: 0 15px;
    margin: 0;

    .route-container {
      display: flex;
      flex-direction: column;
      height: calc(100% - 66px);
      padding: 15px 0;

      .route-inner-container {
        overflow-x: hidden;
        padding: 20px;
      }
    }
  }
}

.modules-settings {
  .modules-settings-container {
    padding: 0 15px;
    margin: 0;
    overflow: hidden;

    h2 {
      padding-left: 32px;
    }

    .mat-mdc-dialog-content {
      max-height: 75vh;
    }

    .route-container {
      display: flex;
      flex-direction: column;
      height: 100%;
      padding: 15px 0;
      overflow-y: hidden;

      .route-inner-container {
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        padding: 20px;
      }
    }
  }
}

.avatar-img {
  border-radius: 50%;
  height: 100%;
}

.avatar-sm {
  height: 24px;
}

.color-special {
  color: $timeline-border-color-orange;
}

.mat-manage {
  color: #fff !important;
  background-color: $timeline-border-color-orange !important;
}

.mat-manage-outline {
  color: $timeline-border-color-orange !important;
  border-color: $timeline-border-color-orange !important;
}
.mat-manage-outline[disabled] {
  color: $timeline-border-color-orange !important;
}

.mat-path {
  color: #fff !important;
  background-color: $timeline-border-color-orange !important;
}
.mat-path[disabled] {
  background-color: $timeline-border-color-orange !important;
}

.mat-path-outline {
  color: $timeline-border-color-orange !important;
  border-color: $timeline-border-color-orange !important;
}
.mat-path-outline[disabled] {
  color: $timeline-border-color-orange !important;
}

.mat-modules {
  color: #fff !important;
  background-color: $timeline-border-color-orange !important;
}
.mat-modules[disabled] {
  background-color: $timeline-border-color-orange !important;
}

.mat-modules-outline {
  color: #ff6200 !important;
  border-color: #fc762383 !important;
}
.mat-modules-outline[disabled] {
  color: #fc762383 !important;
}

/* ------------------------------- */

.overflow-hidden {
  overflow: hidden !important;
}
