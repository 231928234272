mat-select.mat-mdc-select {
  font-family: $timeline-font;
  letter-spacing: normal;
}

.mat-mdc-select-panel.timeline {
  border-radius: 16px !important;
  font-size: 14px;
  min-width: max-content;
  &.br-6 {
    border-radius: 6px !important;
  }
  &.panel-min-width-auto {
    min-width: auto;
  }
  &.panel-min-width-350 {
    min-width: 350px;
  }
  &.panel-max-height-fit-content {
    max-height: fit-content;
  }

  &::-webkit-scrollbar {
    background-color: $timeline-color-white;
    width: 12px;
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
  }
  &::-webkit-scrollbar-track {
    background-color: $timeline-color-white;
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
  }
  &::-webkit-scrollbar-track-piece {
    background-color: $timeline-content-editor-track-piece;
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;

  }
  &::-webkit-scrollbar-thumb {
    background-color: $timeline-content-editor-scrollbar-thumb;
    border-radius: 20px;
  }
  // FireFox scrollbar style
  @supports (not selector(::-webkit-scrollbar)) {
    scrollbar-width: thin;
  }
}

mat-option[timeline] {
  min-height: var(--timeline-min-height, 30px);
  height: var(--timeline-height, auto);
  font-size: var(--timeline-font-size, 14px);
  .mdc-list-item__primary-text {
    display: flex;
    align-items: center;
    gap: 5px;
  }
}

mat-option[timeline=orange] {
  &.mat-mdc-option-multiple {
    .mat-pseudo-checkbox {
      &.mat-pseudo-checkbox-full {
        border-radius: 4px;
        border-width: 1px;
        background-color: $timeline-background-color;
        border-color: $timeline-border-color;
        width: 16px;
        height: 16px;
        &:after {
          width: 8px;
        }
        &.mat-pseudo-checkbox-checked {
          background-color: $timeline-background-color-orange;
          border-color: $timeline-background-color-orange;
        }
      }
    }
  }
}

mat-select[timeline] {
  .mat-mdc-select-trigger {
    font-size: var(--mat-mdc-select-trigger-font-size, 14px);
    width: var(--mat-mdc-select-trigger-width, 100%);
    gap: var(--mat-mdc-select-trigger-gap);
    .mat-mdc-select-value {
      color: var(--mat-mdc-select-value-color, #000);
    }
    .mat-mdc-select-arrow-wrapper {
      --mat-select-focused-arrow-color: #F87832;
      color: var(--mat-mdc-select-arrow-wrapper-color, #000);
      width: var(--mat-mdc-select-arrow-wrapper-width);
      height: var(--mat-mdc-select-arrow-wrapper-height, 24px);
      border-left: var(--mat-mdc-select-arrow-wrapper-border-left);
      border-right: var(--mat-mdc-select-arrow-wrapper-border-right);
      border-top: var(--mat-mdc-select-arrow-wrapper-border-top);
      transform: var(--mat-mdc-select-arrow-wrapper-transformt, translateY(0));
      .mat-mdc-select-arrow {
        display: var(--mat-mdc-select-arrow-display, block);
      }
    }
    .mat-mdc-select-value {
      text-align: var(--mat-mdc-select-value-text-align, left);
      padding: var(--mat-mdc-select-value-text-padding, 0);
    }
    mat-select-trigger {
      line-height: 1;
      white-space: break-spaces;
    }
  }
  &.mat-mdc-select-disabled {
    opacity: 0.5;
  }
}

mat-select[timeline][select-arrow=chevron] {
  --mat-select-enabled-arrow-color: #343A40;
  .mat-mdc-select-trigger {
    .mat-mdc-select-arrow-wrapper {
      .mat-mdc-select-arrow {
        visibility: var(--mat-mdc-select-arrow__visibility, visible);
        width: 14px;
        height: 10px;
        svg {
          display: none;
        }
        &:before {
          font-family: "tabler-icons";
          content: "\ea5f";
          font-size: 16px;
          position: absolute;
          top: -6px;
          left: -1px;
        }
      }
    }
  }
}

mat-select[timeline][tl-align-selected-text=right] {
  --mat-mdc-select-value-text-align: right;
  --mat-mdc-select-value-text-padding: 0 5px 0 0;
}

mat-select[timeline][tl-selected-value-underline] {
  .mat-mdc-select-trigger {
    .mat-mdc-select-value {
      .mat-mdc-select-value-text {
        position: relative;
        &:before {
          content: "";
          bottom: -2px;
          width: 100%;
          height: 1px;
          background: rgba(0, 0, 0, 0.42);
          position: absolute;
        }
      }
    }
    .mat-mdc-select-arrow-wrapper {
      position: relative;
      &:before {
        content: "";
        bottom: 2px;
        left: -5px;
        width: 15px;
        height: 1px;
        background: rgba(0, 0, 0, 0.42);
        position: absolute;
      }
    }
  }
}

.mat-mdc-autocomplete-panel {
  .mat-mdc-option {
    .mdc-list-item__primary-text {
      width: 100%;
    }
  }
  &.users-select-list-autocomplete,
  &.tags-select-list-autocomplete {
    border-radius: 16px !important;

    &::-webkit-scrollbar {
      background-color: $timeline-color-white;
      width: 12px;
      border-top-right-radius: 16px;
      border-bottom-right-radius: 16px;
    }
    &::-webkit-scrollbar-track {
      background-color: $timeline-color-white;
      border-top-right-radius: 16px;
      border-bottom-right-radius: 16px;
    }
    &::-webkit-scrollbar-track-piece {
      background-color: $timeline-content-editor-track-piece;
      border-top-right-radius: 16px;
      border-bottom-right-radius: 16px;

    }
    &::-webkit-scrollbar-thumb {
      background-color: $timeline-content-editor-scrollbar-thumb;
      border-radius: 20px;
    }
    // FireFox scrollbar style
    @supports (not selector(::-webkit-scrollbar)) {
      scrollbar-width: thin;
    }

  }
}

.tl-overlay-panel-position-x-5 {
  transform: translate(-5px);
}
